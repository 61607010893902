import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'qrcode-form',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
  public disableInputs: boolean = false;

  public QRCodeForm: FormGroup;
  @Input() public part: Part;
  @Input() public expired: boolean;
  public enableSaveButton: boolean = false;
  public qrCode: string = '';

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private translate: TranslateService) {

  }

  ngOnInit() {
    this.buildForm();
    let postData = {
      cht: 'qr',
      chs: '500x500',
      chl: this.part.id
    }
    this.qrCode = "https://api.qrserver.com/v1/create-qr-code/?size= " + postData.chs +  " &data=" + postData.chl;
    // this.qrCode = "https://chart.googleapis.com/chart?cht=" + postData.cht + "&chs=" + postData.chs + "&chl=" + postData.chl;

    if(this.expired){
      this.QRCodeForm.disable();
      this.disableInputs = true;
    }
  }


  private buildForm(): void {
    this.QRCodeForm = this.formBuilder.group({
      qs_has_qrcode: [this.part.settings.qs_has_qrcode, [Validators.required]],
      qs_text: [this.part.settings.qs_text, [Validators.maxLength(155)]],
      qs_qrcode_mandatory: [this.part.settings.qs_qrcode_mandatory, [Validators.required]],
      qs_qr_code_url: [this.part.settings.qs_qr_code_url, []]
    });

    setTimeout(() => {
      this.QRCodeForm.valueChanges.subscribe(() => {
        if(this.QRCodeForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)
  }

  public patchPartFromParent(): void {
    let valuesToUpdate = Object.assign({}, this.QRCodeForm.value);

    if(this.QRCodeForm.get('qs_qr_code_url').value && this.QRCodeForm.get('qs_qr_code_url').value !== "") {
      delete valuesToUpdate.qs_qr_code_url;
    } else {
      let postData = {
        cht: 'qr',
        chs: '500x500',
        chl: this.part.id
      }
      let url = "https://api.qrserver.com/v1/create-qr-code/?size= " + postData.chs +  " &data=" + postData.chl;
      // let url = "https://chart.googleapis.com/chart?cht=" + postData.cht + "&chs=" + postData.chs + "&chl=" + postData.chl;
      this.QRCodeForm.get('qs_qr_code_url').setValue(url);
      valuesToUpdate.qs_qr_code_url = url;
    }

    this.patchPart.emit(valuesToUpdate);
  }

  public onSubmit(values: any): void {
  }

  public printQR(): boolean {
    let mywindow = window.open('', 'PRINT');
    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(document.getElementById("qr_code_" + this.part.id).innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
  }

}
