import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from '../../../service/subscription.service';
import { environment } from '../../../../environments/environment';
import { MatDialog, MatStepper } from '@angular/material';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
// import { MatStepper } from '@angular/material';

@Component({
  selector: 'app-business-abonnement',
  templateUrl: './business-abonnement.component.html',
  styleUrls: ['./business-abonnement.component.scss']
})
export class BusinessAbonnementComponent implements OnInit {
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;
  public user: any;
  public product: any;
  public firm: any;
  private subscription: any;
  public selectedProduct: any;

  private priceSelected: any;

  public starterForm: FormGroup;
  public firmDataForm: FormGroup;
  public isBusiness: boolean = false;

  public showConfirmed: boolean = false;
  public showLoader: boolean = false;

  private frontEndUrl: string = environment.frontEndUrl;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private sService: SubscriptionService, private router: Router, private dialog: MatDialog) {
    this.user = this.route.snapshot.data.data.user;
    this.subscription = this.route.snapshot.data.data.subscription;
    this.firm = this.route.snapshot.data.data.firm;
    this.product = this.route.snapshot.data.products;

    // if user already had a business subscription, disable the one he/she already had
    // this.product.price.forEach((value, key) => {
    //   if(key == 0)
    //     this.priceSelected = value;
    //   value.disabled = false;
    // });

    // if(this.subscription.type == "zakelijk_abonnement") {
    //   this.product.price.forEach((value, key) => {
    //     if(value.tour_starts == this.subscription.tour_starts) {
    //       value.disabled = true;
    //       if(this.product.price[key + 1])
    //         this.priceSelected = this.product.price[key + 1];
    //     }
    //   });
    // }
  }
  setSubscription(product: any){
    this.selectedProduct = product;
  }

  setBusiness(business: boolean){
    this.isBusiness = business;
  }

  ngOnInit() {
    this.starterForm = this.formBuilder.group({
      amountOfMonths: [1, [Validators.required, Validators.min(1)]],
      personalOrFirm: ['', [Validators.required]],
      autoRenewal: [false, []],
      // incasso: [false, []]
    });

    this.firmDataForm = this.formBuilder.group({
      name: [this.firm ? this.firm.name : "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      street: [this.firm ? this.firm.street : "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]], // kenmerk moneybird api?
      number: [this.firm ? this.firm.number : "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      invoice_email: [this.firm ? this.firm.invoice_email : "", [Validators.required, Validators.email, Validators.minLength(1), Validators.maxLength(255)]],
      city: [this.firm ? this.firm.city : "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      zipcode: [this.firm ? this.firm.zipcode : "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      kenmerk: [this.firm && this.firm.kenmerk ? this.firm.kenmerk : "", [Validators.minLength(1), Validators.maxLength(255)]],
      country: [this.firm ? this.firm.country : "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      contact_surname: [this.firm ? this.firm.contact_surname : "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      contact_name: [this.firm ? this.firm.contact_name : "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]]
    });
  }
  public payWithIdealPersonal(){
    let data: any = {};
    data.selectedProduct = this.selectedProduct;
    data.amountOfMonths = this.starterForm.get('amountOfMonths').value;
    data.autoRenewal = this.starterForm.get('autoRenewal').value;
    data.reDirectUrl = this.frontEndUrl + '/account/subscription';
    this.sService.payWithIdealPersonal(data).then(result => {
      this.showLoader = false;
      if(result['status'] == 'successful')
        window.location.href = result['url'];
    });
  }

  public enableBusinessSubscription(): void {
    let data = this.firmDataForm.value;
    data.selectedProduct = this.selectedProduct;
    data.amountOfMonths = this.starterForm.get('amountOfMonths').value;
    data.autoRenewal = this.starterForm.get('autoRenewal').value;
    this.showLoader = true;

    this.sService.enableBusinessSubscription(data).then(result => {
      this.showLoader = false;
      if(result['status'] == 'successful')
        this.showConfirmed = true;
      else{
        const dialogRef = this.dialog.open(ConfirmDialog, {
          data: {
            title: "Fout! ",
            content: "We kunnen geen automatische facturen sturen naar het ogpegeven email adres. Controleer en probeer het opnieuw of neem contact op met info@tourtodo.com",
            showCancel: false
          },
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigate(['/chooseplan/business']);
        });
      }
    });
  }

  public enableBusinessSubscriptionAndPay(): void {
    let data = this.firmDataForm.value;
    data.selectedProduct = this.selectedProduct;
    data.amountOfMonths = this.starterForm.get('amountOfMonths').value;
    data.autoRenewal = this.starterForm.get('autoRenewal').value;
    this.showLoader = true;
    data.reDirectUrl = this.frontEndUrl + '/account/subscription';

    this.sService.enableBusinessSubscriptionAndPay(data).then(result => {
      this.showLoader = false;
      if(result['status'] == 'successful')
        window.location.href = result['url'];
    });
  }

}
