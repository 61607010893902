import { Injectable } from '@angular/core';
import { TourService } from '../service/tour.service';
import { forkJoin, of } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GetPartsService implements Resolve<any> {

  constructor(private tApi: TourService) {

  }

  resolve(route: ActivatedRouteSnapshot) {

    let tourID = route.parent.paramMap.get('id');

    return forkJoin(
      this.tApi.getTourParts(tourID).then(result => {
        if(result['status'] == 'successful')
          return result;
        else
          return [];
      }).catch(error => {
        // do some logging
        console.log(error);
        return [];
      }),
      of(tourID)
    );
  }
}
