import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tour } from '../../../interfaces/tour';
import { TourService } from '../../../service/tour.service';
import { UploadPhoto } from '../../../dialogs/upload_photo/upload_photo.dialog';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'creator-tours-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class CreatorToursView implements OnInit {

  public tour: Tour;
  public imageUploading: boolean = false;
  public showEditTourName: boolean = false;
  public expired: boolean;
  public warning: boolean;
  public free: boolean;

  constructor(private route: ActivatedRoute, public router: Router, private tApi: TourService, private dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {
    this.tour = this.route.snapshot.data.tour.tour;
    this.expired = this.route.snapshot.data.tour.expire_data.expired;
    this.free = this.route.snapshot.data.tour.expire_data.free;
    this.warning = this.route.snapshot.data.tour.expire_data.warning;
  }

  public saveNewTourName(): void {
    if(this.tour.name && this.tour.name !== "") {
      let data = {
        tourID: this.tour.id,
        name: this.tour.name
      }
      this.tApi.editTourName(data);
    }
  }

  public previewImage(event): void {
    if(event.target && event.target.files && event.target.files.length > 0) {
      const dialogRef = this.dialog.open(UploadPhoto, {
        width: '600px',
        data: {
          title: this.translate.instant('creator.parts.dialog_change_image'),
          circle: false,
          maintain_aspect_ratio: false,
          aspect_ratio_1: 1,
          aspect_ratio_2: 1,
          event: event
        }
      });
      dialogRef.afterClosed().subscribe((data) => {
        if(data && data.img) {
          this.imageUploading = true;
          this.updateTourImage(data.img);
        }
      });
    }
  }

  private updateTourImage(image: string): void {
    this.tApi.updateTourImage({image: image, tourID: this.tour.id}).then(result => {
      this.imageUploading = false;
      this.tour.image = result['url'];
    }).catch(error => {
      console.log(error);
      this.imageUploading = false;
      alert("Something went wrong");
    });
  }

  public deleteTourImage(): void {
    this.imageUploading = true;
    this.tApi.deleteTourImage(this.tour.id).then(() => {
      this.imageUploading = false;
      this.tour.image = null;
    }).catch(error => {
      console.log(error);
      this.imageUploading = false;
      alert("Something went wrong");
    });
  }
}
