import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreatorLayout } from '../directives/layout/creator/creator-layout.component';
import { HomeLayout } from '../directives/layout/home/home-layout.component';

// creator - components
import { CreatorToursList } from '../creator/tours/list/list.component';
import { CreatorToursView } from '../creator/tours/view/view.component';
import { CreatorPartsView } from '../creator/parts/view/view.component';

// results - components
import { MainResults } from '../results/main/main.component';
import { ShowResults } from '../results/show/show.component';
import { DetailResults } from '../results/detail/detail.component';

import { ClicktilesComponent } from '../directives/forms/clicktiles/clicktiles.component';
import { ClickmapComponent } from '../directives/forms/clickmap/clickmap.component';
import { ClickmapComponentTour } from '../directives/tours/settings/clickmap/clickmap.component';

// home - components
import { HomeTOURtoDO } from '../home/tourtodo/tourtodo.component';
import { LoginComponent } from '../home/login/login.component';
import { RegisterComponent } from '../home/register/register.component';
import { AccountComponent } from '../home/account/account.component';
import { MuseumComponent } from '../home/museum/museum.component';
import { TourismComponent } from '../home/tourism/tourism.component';
import { EducationComponent } from '../home/education/education.component';
import { EventsComponent } from '../home/events/events.component';
import { PersonalComponent } from '../home/personal/personal.component';
import { ContactComponent } from '../home/contact/contact.component';
import { VerifyEmailComponent } from '../home/verify-email/verify-email.component';
import { ForgotPasswordComponent } from '../home/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../home/reset-password/reset-password.component';
import { ChoosePlanComponent } from '../home/choose-plan/choose-plan.component';
import { BusinessAbonnementComponent } from '../home/subscription/business-abonnement/business-abonnement.component';
import { AvgComponent } from '../home/avg/avg.component';
import { PrivacyComponent } from '../home/privacy/privacy.component';
import { TermsConditionsComponent } from '../home/terms_conditions/terms_conditions.component';

// guards
import { AuthGuard } from '../guards/auth.guard';
import { LoginGuard } from '../guards/login.guard';
import { AdminGuard } from '../guards/admin.guard';

// directives
import { ToursOverview } from '../directives/tours/overview/overview.component';
import { ToursSettings } from '../directives/tours/settings/settings.component';

  //account
import { AccountDataComponent } from './../directives/account/data/data.component';
import { AccountInvoiceComponent } from './../directives/account/invoice/invoice.component';
import { AccountInvoiceDataComponent } from './../directives/account/invoice-data/invoice-data.component';
import { AccountSubscriptionComponent } from './../directives/account/subscription/subscription.component';
import { AccountChangePasswordComponent } from './../directives/account/change-password/change-password.component';
import { AccountDeviceComponent } from './../directives/account/device/device.component';

// resolvers
import { GetToursService } from '../resolvers/get-tours.service';
import { GetTourService } from '../resolvers/get-tour.service';
import { GetPartsService } from '../resolvers/get-parts.service';
import { GetTourSettingsService } from '../resolvers/get-tour-settings.service';
import { GetTourSettingsClicktilesService } from '../resolvers/get-tour-settings-clicktiles.service';
import { GetPartService } from '../resolvers/get-part.service';
import { GetUserService } from '../resolvers/get-user.service';
import { GetDevicesService } from '../resolvers/get-devices.service';
import { GetInvoicesService } from '../resolvers/get-invoices.service';
import { GetProductsService } from '../resolvers/get-products.service';
import { GetResultService } from '../resolvers/get-result.service';
import { GetSpecificResultService } from '../resolvers/get-specific-result.service';

// Admin
import { AdminComponent } from '../admin/admin/admin.component';

const routes: Routes = [{
  path: '',
  component: HomeLayout,
  children: [{
    path: '',
    component: HomeTOURtoDO
  }, {
    path: 'processing-agreement',
    component: AvgComponent
  }, {
    path: 'privacy',
    component: PrivacyComponent
  }, {
    path: 'terms',
    component: TermsConditionsComponent
  }, {
    path: 'museum',
    component: MuseumComponent
  }, {
    path: 'tourism',
    component: TourismComponent
  }, {
    path: 'education',
    component: EducationComponent
  }, {
    path: 'event',
    component: EventsComponent
  }, {
    path: 'personal',
    component: PersonalComponent
  }, {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    canActivate: [LoginGuard]
  }, {
    path: 'resetpassword/:hash',
    component: ResetPasswordComponent,
    canActivate: [LoginGuard]
  }, {
    path: 'verify/:hash',
    canActivate: [LoginGuard],
    component: VerifyEmailComponent
  }, {
    path: 'login',
    canActivate: [LoginGuard],
    component: LoginComponent
  }, {
    path: 'register',
    component: RegisterComponent,
    canActivate: [LoginGuard]
  }, {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
    resolve: { data: GetUserService },
    children: [{
      path: '',
      redirectTo: '/account/subscription',
      pathMatch: 'full'
    }, {
      path: 'data',
      component: AccountDataComponent,
      resolve: { data: GetUserService }
    }, {
      path: 'invoice/data',
      component: AccountInvoiceDataComponent,
      resolve: { data: GetUserService }
    }, {
      path: 'invoice/history',
      component: AccountInvoiceComponent,
      resolve: { invoices: GetInvoicesService }
    }, {
      path: 'subscription',
      component: AccountSubscriptionComponent,
      resolve: { data: GetUserService }
    }, {
      path: 'device',
      component: AccountDeviceComponent,
      resolve: { devices: GetDevicesService }
    }, {
      path: 'password/change',
      component: AccountChangePasswordComponent,
      resolve: { data: GetUserService }
    }]
  }, {
    path: 'chooseplan',
    component: ChoosePlanComponent,
    resolve: { data: GetUserService, products: GetProductsService }
  }, {
    path: 'chooseplan/business',
    component: BusinessAbonnementComponent,
    canActivate: [AuthGuard],
    resolve: { data: GetUserService, products: GetProductsService }
  }, {
    path: 'contact',
    component: ContactComponent
  }]
}, {
  path: 'creator',
  component: CreatorLayout,
  canActivate: [AuthGuard],
  children: [{
    path: '',
    redirectTo: '/creator/tours',
    pathMatch: 'full'
  }, {
    path: 'tours',
    component: CreatorToursList,
    resolve: { tours: GetToursService }
  }, {
    path: 'tours/:id',
    component: CreatorToursView,
    resolve: { tour: GetTourService },
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        path: 'overview',
        component: ToursOverview,
        resolve: { parts: GetPartsService }
      },
      {
        path: 'settings',
        component: ToursSettings,
        resolve: { settings: GetTourSettingsService },
        children: [{
          path: 'clickmap',
          component: ClickmapComponentTour,
          resolve: { settings: GetTourSettingsClicktilesService }
        }]
      }
    ]
  }, {
    path: 'tours/:tourid/parts/:partid',
    component: CreatorPartsView,
    resolve: { part: GetPartService },
    children: [{
      path: 'clicktiles',
      component: ClicktilesComponent,
      resolve: { part: GetPartService }
    }, {
      path: 'clickmap',
      component: ClickmapComponent,
      resolve: { part: GetPartService }
    }]
  }]
}, {
  path: 'results',
  component: CreatorLayout,
  canActivate: [AuthGuard],
  children: [{
    path: '',
    redirectTo: '/results/tours/all',
    pathMatch: 'full'
  }, {
    path: 'tours',
    component: MainResults,
    resolve: { tours: GetToursService },
    children: [
      {
        path: 'all',
        component: ShowResults
      },
      {
        path: ':id',
        component: ShowResults,
        resolve: { results: GetResultService },
      }
    ]
  }, {
    path: 'tours/:id/:resultid',
    component: DetailResults,
    resolve: { result: GetSpecificResultService },
  }]
}, {
  path: 'admin',
  component: CreatorLayout,
  canActivate: [AuthGuard, AdminGuard],
  children: [{
    path: '',
    redirectTo: '/admin/home',
    pathMatch: 'full'
  }, {
    path: 'home',
    component: AdminComponent
  }]
}, {
  path: '',
  pathMatch: 'full',
  redirectTo: '/creator/tours'
}];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ]
})
export class TourtodoRoutingModule { }
