import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'copy-tour',
  templateUrl: './copy-tour.dialog.html',
  styleUrls: ['./copy-tour.dialog.scss']
})
export class CopyTourDialog implements OnInit {

  public tourID: string;
  public copyTour: FormGroup;
  private tourName: string;

  constructor(
    public dialogRef: MatDialogRef<CopyTourDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder
  ) {
    this.tourID = this.data.tourID;
    this.tourName = this.data.tourName;
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.copyTour = this.formBuilder.group({
      tour_title: [this.tourName, [Validators.required]]
    });
  }

  public submit() {
    let data = this.copyTour.value;
    this.dialogRef.close(data);
  }

  public dismiss() {
    this.dialogRef.close();
  }
}
