import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import { UploadPhoto } from '../../../dialogs/upload_photo/upload_photo.dialog';
import { MatDialog } from '@angular/material';
import * as CustomEditor from '../../../../assets/js/ckeditor.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'slidingpuzzle-form',
  templateUrl: './slidingpuzzle.component.html',
  styleUrls: ['./slidingpuzzle.component.scss']
})
export class SlidingpuzzleComponent implements OnInit {
  public ckEditor: any = CustomEditor;
  public config_sp_assignment: any = {
    placeholder: this.translate.instant('forms.slidingpuzzle.assignment_body')
  }
  public config_sp_message_after_solving: any = {
    placeholder: this.translate.instant('forms.slidingpuzzle.after_solve_body')
  }
  public disableInputs: boolean = false;
  public model: any = {
    sp_assignment: "",
    sp_message_after_solving: ""
  }

  public slidingpuzzleForm: FormGroup;
  @Input() public part: Part;
  @Input() public expired: boolean;

  public enableSaveButton: boolean = false;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {
    this.model.sp_assignment = this.part.settings.sp_assignment;
    this.model.sp_message_after_solving = this.part.settings.sp_message_after_solving;
    this.buildForm();

    if(this.expired){
      this.slidingpuzzleForm.disable();
      this.disableInputs = true;
    }
  }

  public patchPartFromParent(includeImage: boolean): void {
    let valuesToUpdate = Object.assign({}, this.slidingpuzzleForm.value);

    if(!includeImage)
      delete valuesToUpdate.sp_image;

    this.patchPart.emit(valuesToUpdate);
  }

  public informationChanged() {
    this.patchPart.emit(this.model);
  }

  private buildForm(): void {
    this.slidingpuzzleForm = this.formBuilder.group({
      //sp_assignment: [this.part.settings.sp_assignment, [Validators.required, Validators.maxLength(155)]],
      sp_image_type: [this.part.settings.sp_image_type, [Validators.required]],
      sp_image: [this.part.settings.sp_image, [Validators.required]],
      sp_tiles: [this.part.settings.sp_tiles, [Validators.required]],
      //sp_message_after_solving: [this.part.settings.sp_message_after_solving, [Validators.maxLength(155)]]
      //oq_cq_has_allowed_time: [this.part.settings.oq_cq_has_allowed_time, [Validators.required]],
      //oq_cq_allowed_time: [this.part.settings.oq_cq_allowed_time, [Validators.min(0)]]
    });

    this.slidingpuzzleForm.get('sp_image_type').valueChanges.subscribe(value => {
      if(value == "upload")
        this.slidingpuzzleForm.get('sp_image').setValidators([Validators.required]);
      else
        this.slidingpuzzleForm.get('sp_image').clearValidators();

      this.slidingpuzzleForm.get('sp_image').updateValueAndValidity();
    });

    setTimeout(() => {
      this.slidingpuzzleForm.valueChanges.subscribe(() => {
        if(this.slidingpuzzleForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)
  }

  public onSubmit(values: any): void{
  }

  public previewImage(event): void {
    if(event.target && event.target.files && event.target.files.length > 0) {
      const dialogRef = this.dialog.open(UploadPhoto, {
        width: '600px',
        data: {
          title: this.translate.instant('forms.slidingpuzzle.change_image'),
          circle: false,
          maintain_aspect_ratio: true,
          aspect_ratio_1: 4,
          aspect_ratio_2: 4,
          event: event
        }
      });
      dialogRef.afterClosed().subscribe((data) => {
        if(data && data.img) {
          this.slidingpuzzleForm.get('sp_image').setValue(data.img);
          this.patchPartFromParent(true);
        }
      });
    }
  }

}
