import { Component, OnInit } from '@angular/core';
import { TourService } from '../../../service/tour.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
import { ShareTourDialog } from '../../../dialogs/share-tour/share-tour.dialog';
import { CopyTourDialog } from '../../../dialogs/copy-tour/copy-tour.dialog';
import { CreateTourDialog } from '../../../dialogs/create-tour/create-tour.dialog';
import { MatDialog } from '@angular/material';

export interface tours {
  id: string;
  image: string;
  description: string;
  amount_of_parts: number;
  tourcode: string;
}

@Component({
  selector: 'creator-tours-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class CreatorToursList implements OnInit {

  public tours: tours[] = [];
  public loader: boolean = false;
  public expired: boolean;
  public warning: boolean;
  public subscription: any;
  public free: boolean;
  public totalTours: number;
  public allowedTours: number;

  constructor(private tApi: TourService, private translate: TranslateService, private router: Router, private dialog: MatDialog, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.free = this.route.snapshot.data.tours.expire_data.free;
    this.expired = this.route.snapshot.data.tours.expire_data.expired;
    this.warning = this.route.snapshot.data.tours.expire_data.warning;
    this.tours = this.route.snapshot.data.tours.tours || [];
    this.subscription = this.route.snapshot.data.tours.subscription;
    this.allowedTours = this.subscription.tours;
    this.totalTours = this.tours.length;
    //this.tours = this.tours.slice(0, this.allowedTours);
  }

  public createTour(tabIndex: number): void {

    const dialogRef = this.dialog.open(CreateTourDialog, {
      disableClose: true,
      width: '450px',
      data: {
        tabIndex: tabIndex
      },
      panelClass: 'create_tour'
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data) {
        this.loader = true;

        if(data.tab_index) { // copy tour
          this.tApi.importTour({ tourcode: data.tour_code }).then(result => {
            this.loader = false;
            this.handleImport(result);
          }).catch(error => {
            console.log(error);
            this.loader = false;
            alert("Something went wrong");
          });
        } else { // create new tour
          this.tApi.createTour({ name: data.tour_name }).then(result => {
            this.router.navigate(["/creator/tours/", result['tourID']]);
            this.loader = false;
          }).catch(error => {
            console.log(error);
            this.loader = false;
            alert("Something went wrong");
          });
        }
      }
    });
  }

  private handleImport(result: any): void {
    if(result.status == "successful" && result.message && result.message == "no_tour") {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        data: {
          title: this.translate.instant('creator.tours.view.invalid_tour_code'),
          showCancel: false
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(() => {
        this.createTour(1);
      });
    } else if(result.status == "successful" && result.tour) {
      this.router.navigate(["/creator/tours/", result.tour.id]);
    }
  }

  public copyTour(tour: any): void {
    const dialogRef = this.dialog.open(CopyTourDialog, {
      disableClose: true,
      width: '350px',
      data: {
        tourID: tour.id,
        tourName: tour.name
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.tour_title) {
        this.loader = true;
        let submitData = {
          current_tour: tour.id,
          tour_title: data.tour_title
        }
        this.tApi.copyTour(submitData).then((result: any) => {
          this.loader = false;
          this.tours.push(result.tour);
        }).catch(error => {
          console.log(error);
          this.loader = false;
          alert("Something went wrong");
        });
      }
    });
  }

  public shareTour(tour: any): void {
    const dialogRef = this.dialog.open(ShareTourDialog, {
      disableClose: true,
      width: '325px',
      data: {
        tourID: tour.id
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data) {
        let postData = {
          newUsers: data.newUsers && data.newUsers[0].email !== "" ? data.newUsers : [],
          usersToDelete: data.usersToRemove ? data.usersToRemove : [],
          tourID: tour.id
        }
        this.tApi.shareTour(postData).then(() => {
          this.displayNotification();
        }).catch(error => {
          console.log(error);
          alert("Something went wrong");
        });
      }
    });
  }

  private displayNotification() {
    this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('creator.tours.view.dialog_changes_done'),
        showCancel: false
      },
      disableClose: true
    });
  }

  public deleteTour(tour: any, index: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('creator.tours.view.dialog_confirm_delete'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.loader = true;
        this.tApi.deleteTour(tour.id).then(() => {
          this.loader = false;
          this.tours.splice(index, 1);
        }).catch(error => {
          console.log(error);
          this.loader = false;
          alert("Something went wrong");
        });
      }
    });
  }

}
