import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Part } from '../../../interfaces/part';
import * as CustomEditor from '../../../../assets/js/ckeditor.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'informationmoment-form',
  templateUrl: './informationmoment.component.html',
  styleUrls: ['./informationmoment.component.scss']
})
export class InformationmomentComponent implements OnInit {

  @Input() public part: Part;
  @Input() public expired: boolean;

  public ckEditor: any = CustomEditor;
  public config: any = {
    placeholder: this.translate.instant('forms.information_moment.text')
  }
  public disableInputs: boolean = false;
  public model: any = {
    im_description: ""
  }

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.model.im_description = this.part.settings.im_description;

    if(this.expired)
      this.disableInputs = true;
  }

  public informationChanged() {
    this.patchPart.emit(this.model);
  }
}
