import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../../../service/subscription.service';
import { CustomDialog } from '../../../dialogs/custom/custom.dialog';
import { DeleteAccountDialog } from '../../../dialogs/delete-account/delete-account.dialog';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class AccountDataComponent implements OnInit {

  public user: any;
  public products: any;
  public subscription: any;
  public expired: boolean;
  public warning: boolean;

  constructor(private aService: AuthService, private router: Router, private sService: SubscriptionService, private route: ActivatedRoute, private dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {
    this.sService.getPackages().then(result => {
      this.products = result;
    });

    this.user = this.route.snapshot.data.data.user;
    this.expired = this.route.snapshot.data.data.expire_data.expired;
    this.warning = this.route.snapshot.data.data.expire_data.warning;
    this.subscription = this.route.snapshot.data.data.subscription;
    this.subscription.start_date ? this.subscription.start_date = new Date(this.subscription.start_date) : undefined;
    this.subscription.end_date ? this.subscription.end_date = new Date(this.subscription.end_date) : undefined;
  }

  public downloadData(): void {
    const dialogRef = this.dialog.open(CustomDialog, {data: {
          title: this.translate.instant('account.account-data.pop-up.data_request_title'),
          content: this.translate.instant('account.account-data.pop-up.data_request_content'),
          confirmButton: this.translate.instant('account.account-data.pop-up.data_request_confirm'),
          cancelButton: this.translate.instant('account.account-data.pop-up.data_request_cancel'),
          showCancel: true
        },
        disableClose: true
      });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.sService.requestDataByMail().then(() => {
          this.showSucceedAlert();
        });
      }
    });
  }

  public deleteAccount(): void {
    const dialogRef = this.dialog.open(DeleteAccountDialog, {data: {
          title: this.translate.instant('account.account-data.pop-up.account_cancel_title'),
          content: this.translate.instant('account.account-data.pop-up.account_cancel_content'),
          confirmButton: this.translate.instant('account.account-data.pop-up.account_cancel_confirm'),
          cancelButton: this.translate.instant('account.account-data.pop-up.account_cancel_cancel'),
          showCancel: true
        },
        disableClose: true
      });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.sService.deleteAccount().then(() => {
          this.logout();
          this.showSucceedAlertAccountDeleted();
        });
      }
    });
  }

  private showSucceedAlert(): void {
    this.dialog.open(CustomDialog, {
      data: {
        title: this.translate.instant('account.account-data.pop-up.data_request_complete_title'),
        content: this.translate.instant('account.account-data.pop-up.data_request_complete_content'),
        showCancel: false
      },
      disableClose: true
    });
  }

  public logout(): void {
    this.aService.logout().then(() => {
      localStorage.removeItem('access_token');
      this.router.navigate(['/login']);
    });
  }

  private showSucceedAlertAccountDeleted(): void {
    this.dialog.open(CustomDialog, {
      data: {
        title: this.translate.instant('account.account-data.pop-up.account_cancel_complete_title'),
        content: this.translate.instant('account.account-data.pop-up.account_cancel_complete_content'),
        showCancel: false
      },
      disableClose: true
    });
  }
}
