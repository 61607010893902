import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';

@Component({
  selector: 'points-form',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})
export class PointsComponent implements OnInit {

  public pointsForm: FormGroup;
  @Input() public part: Part;
  @Input() public tourSettings: any;
  @Input() public expired: boolean;
  public enableSaveButton: boolean = false;
  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.buildForm();
    if(this.expired)
      this.pointsForm.disable();
  }

  public patchPartFromParent(): void {
    this.patchPart.emit(this.pointsForm.value);
  }

  private buildForm(): void {
    this.pointsForm = this.formBuilder.group({
      ps_has_joker: [this.part.settings.ps_has_joker, []],
      ps_penalty_points_joker: [this.part.settings.ps_penalty_points_joker, []],
      ps_points_correct_answer: [this.part.settings.ps_points_correct_answer, [Validators.required]],
      ps_points_wrong_answer: [this.part.settings.ps_points_wrong_answer, [Validators.required]]
    });
    setTimeout(() => {
      this.pointsForm.valueChanges.subscribe(() => {
        if(this.pointsForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)

    this.pointsForm.get('ps_has_joker').valueChanges.subscribe(value => {
      if(value)
        this.pointsForm.get('ps_penalty_points_joker').setValidators([Validators.required]);
      else
        this.pointsForm.get('ps_penalty_points_joker').clearValidators();

      this.pointsForm.get('ps_penalty_points_joker').updateValueAndValidity();
    });
  }

  public onSubmit(values: any): void{
  }

}
