import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pick_part_type',
  templateUrl: './pick-part-type.dialog.html',
  styleUrls: ['./pick-part-type.dialog.scss']
})
export class PickPartTypeDialog implements OnInit {

  public title: string;
  private tourID: string;
  public pickPartTypeForm: FormGroup;
  public showTitleForm: boolean = false;

  public choices: any = [{
    key: this.translate.instant('dialogs.pickPart.key_1'),
    value: [{
      type: "im",
      display: this.translate.instant('dialogs.pickPart.item_1')
    }, {
      type: "cf",
      display: this.translate.instant('dialogs.pickPart.item_2')
    }]
  }, {
    key: this.translate.instant('dialogs.pickPart.key_2'),
    value: [{
      type: "oq",
      display: this.translate.instant('dialogs.pickPart.item_3')
    }, {
      type: "cq",
      display: this.translate.instant('dialogs.pickPart.item_4')
    }, {
      type: "op",
      display: this.translate.instant('dialogs.pickPart.item_5')
    }]
  }, {
    key: this.translate.instant('dialogs.pickPart.key_3'),
    value: [{
      type: "pm",
      display: this.translate.instant('dialogs.pickPart.item_6')
    }, {
      type: "gw",
      display: this.translate.instant('dialogs.pickPart.item_7')
    }, {
      type: "sp",
      display: this.translate.instant('dialogs.pickPart.item_8')
    }]
  }];

  constructor(public dialogRef: MatDialogRef<PickPartTypeDialog>, @Inject(MAT_DIALOG_DATA) private data: any, private formBuilder: FormBuilder, private translate: TranslateService) {
    this.title = this.data.title;
    this.tourID = this.data.tourID;
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.pickPartTypeForm = this.formBuilder.group({
      part_type: ["", [Validators.required]],
      part_title: ["", [Validators.required]]
    });
  }

  public typeChanged(value) {
    this.showTitleForm = true;
    this.pickPartTypeForm.get('part_title').setValue(value.display);
  }

  public submit() {
    let data = this.pickPartTypeForm.value;
    data.tourID = this.tourID;
    this.dialogRef.close(data);
  }

  public dismiss() {
    this.dialogRef.close();
  }

}
