import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private packages: any = undefined;
  private apiURL: string = environment.apiUrl;

  constructor(private translate: TranslateService, private http: HttpClient) {

  }

  public getAllProducts(): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getAllProducts")
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public deleteTestDevice(data: any) {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "deleteTestDevice", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }
  
  public preparePayment(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "preparePayment", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public updateInvoiceData(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "updateInvoiceData", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public requestDataByMail(): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "requestDataByMail")
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public deleteAccount(): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "deleteAccount")
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getAllInvoices(): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getAllInvoices")
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public reSendInvoice(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "reSendInvoice", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }
  public payInvoice(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "payInvoice", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }



  public requestWorkshop(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "requestWorkshop", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public payWithIdealPersonal(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "payWithIdealPersonal", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public enableBusinessSubscription(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "enableBusinessSubscription", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public enableBusinessSubscriptionAndPay(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "enableBusinessSubscriptionAndPay", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public stopAutoRenew(): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "stopAutoRenew", {})
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public startAutoRenew(): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "startAutoRenew", {})
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public switchToFree(): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "switchToFree", {})
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getPackages(): any {
    return new Promise(resolve => {

      this.translate.get('products.zakelijk_abonnement.title').subscribe(() => {
        if(this.packages)
          resolve(this.packages);
        else {
          this.packages = {
            zakelijk_abonnement: {
              title: this.translate.instant('products.zakelijk_abonnement.title')
            },
            particulier_free: {
              title: this.translate.instant('products.particulier_free.title')
            },
            zakelijk_event: {
              title: this.translate.instant('products.zakelijk_event.title')
            },
            particulier_premium: {
              title: this.translate.instant('products.particulier_premium.title')
            },
            zakelijk_workshop: {
              title: this.translate.instant('products.zakelijk_workshop.title')
            }
          }

          resolve(this.packages);
        }
      });
    });
  }
}
