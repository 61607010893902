import { Injectable } from '@angular/core';
import { TourService } from '../service/tour.service';
// import { forkJoin } from 'rxjs';
// import { catchError } from 'rxjs/operators';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GetToursService implements Resolve<any> {

  constructor(private tApi: TourService) {

  }

  resolve() {
    return this.tApi.getTours().then(result => {
      if(result['status'] == 'successful') {
        return result;
      } else {
        return [];
      }
    }).catch(error => {
      // do some logging
      console.log(error);
      return [];
    });
  }
}
