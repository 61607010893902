import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Part } from '../../../interfaces/part';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PartService } from '../../../service/part.service';

@Component({
  selector: 'app-clicktiles',
  templateUrl: './clicktiles.component.html',
  styleUrls: ['./clicktiles.component.scss']
})
export class ClicktilesComponent implements OnInit {

  public part: Part;
  public image: any;

  public tourID: string;
  public enableSaveButton: boolean = false;
  public clickableTilesFormGroup: FormGroup;

  public tiles: any = [{
    id: 2,
    value: "2x2"
  }, {
    id: 3,
    value: "3x3"
  }, {
    id: 4,
    value: "4x4"
  }, {
    id: 5,
    value: "5x5"
  }];

  @ViewChild('myCanvas', { static: true }) myCanvas: ElementRef;
  public context: CanvasRenderingContext2D;

  public expired: boolean;
  public showForm: boolean = false;

  public afterClickTiles: any = [];
  public alwaysVisibleTiles: any = [];
  public neverVisibleTiles: any = [];

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private pApi: PartService) {

  }

  ngOnInit() {
    this.part = this.route.snapshot.data.part[0].part;
    this.expired = this.route.snapshot.data.part[0].expired;
    this.tourID = this.route.snapshot.data.part[1].tourID;

    this.image = new Image();
    this.image.src = this.part.image;

    if(!this.part.settings.oq_cq_has_clickable_tiles || this.expired)
      this.router.navigate(["/creator/tours/" + this.tourID + "/parts/" + this.part.id]);
    else {
      this.showForm = true;
      this.buildForm();

      if(this.part.settings.clicktiles.length == 0) {
        let tiles = parseInt(this.part.settings.oq_cq_tiles);
        this.afterClickTiles = Array(Math.pow(tiles, 2)).fill(0).map((_i: number, x: number) => x + 1);
        setTimeout(() => {
          this.patchPart(true);
        }, 100);
      } else {
        this.part.settings.clicktiles.forEach((value: any) => {
          switch(value['visible']) {
            default:
            case "after_click":
              this.afterClickTiles.push(value.number);
              break;
            case "never":
              this.neverVisibleTiles.push(value.number);
              break;
            case "always":
              this.alwaysVisibleTiles.push(value.number);
              break;
          }
        });
      }
    }
  }

  ngAfterViewInit(): void {
    if(!this.expired) {
      this.context = (<HTMLCanvasElement> this.myCanvas.nativeElement).getContext('2d');

      this.image.onload = () => {
        this.drawLines(parseInt(this.part.settings.oq_cq_tiles));
      }
    }
  }

  public regenerateAfterClickTiles(): void {
    this.afterClickTiles = [];
    this.alwaysVisibleTiles = [];
    this.neverVisibleTiles = [];

    let tiles = this.clickableTilesFormGroup.get('oq_cq_tiles').value;
    this.afterClickTiles = Array(Math.pow(tiles, 2)).fill(0).map((_i: number, x: number) => x + 1);

    this.patchPart(true);
  }

  public drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container)
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    else
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

    this.patchPart(true);
  }

  public patchPart(includeTiles: boolean) {
    this.enableSaveButton = true;
    let valuesToUpdate = Object.assign({}, this.clickableTilesFormGroup.value);

    if(includeTiles) {
      valuesToUpdate.afterClickTiles = this.afterClickTiles;
      valuesToUpdate.alwaysVisibleTiles = this.alwaysVisibleTiles;
      valuesToUpdate.neverVisibleTiles = this.neverVisibleTiles;
    }

    let postData = {
      tourID: this.tourID,
      partID: this.part.id,
      values: valuesToUpdate
    }

    // save to database
    this.pApi.patchPartSettings(postData).then(() => {
      // patch values so that all tabs have the updated object
      let valuesToPatch = Object.keys(postData.values);
      valuesToPatch.forEach(value => {
        this.part.settings[value] = this.clickableTilesFormGroup.value[value];
      });
    });
  }

  public drawLines(tiles: number): void {
    this.context.clearRect(0, 0, this.image.width, this.image.height);
    this.context.drawImage(this.image, 0, 0);
    this.context.font = "45px Arial";

    for(let i = 1; i < tiles; i++) {
      // drawing the horizontal & vertical lines
      this.context.beginPath();
      this.context.moveTo(this.image.width * (i/tiles), 0);
      this.context.lineTo(this.image.width * (i/tiles), this.image.height);
      this.context.stroke();

      this.context.beginPath();
      this.context.moveTo(0, this.image.height * (i/tiles));
      this.context.lineTo(this.image.width, this.image.height * (i/tiles));
      this.context.stroke();
    }

    let wave = 1;
    for(let i = 1; i <= (tiles * tiles); i++) {
      // placing text within the lines
      let width = this.image.width * ( (i % tiles == 0 ? tiles : i % tiles) / tiles);
      let distanceToLeft = 0.5 * (1 / tiles) * this.image.width;
      let height = this.image.height * (wave / tiles);
      let distanceToTop = 0.5 * (1 / tiles) * this.image.height;
      this.context.fillText( i.toString(), width - distanceToLeft, height - distanceToTop);
      if(i % tiles == 0)
        wave++;
    }
  }

  private buildForm(): void {
    this.clickableTilesFormGroup = this.formBuilder.group({
      oq_cq_tiles: [parseInt(this.part.settings.oq_cq_tiles), [Validators.required]],
      oq_cq_tiles_penalty: [this.part.settings.oq_cq_tiles_penalty, [Validators.required, Validators.min(0)]]
    });

    setTimeout(() => {
      this.clickableTilesFormGroup.valueChanges.subscribe(() => {
        if(this.clickableTilesFormGroup.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)
  }

  public saveButton(){
    this.enableSaveButton = false;
    this.router.navigate(["/creator/tours/" + this.tourID + "/parts/" + this.part.id]);
  }

}
