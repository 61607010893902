import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import { UploadPhoto } from '../../../dialogs/upload_photo/upload_photo.dialog';
import { MatDialog } from '@angular/material';
import * as CustomEditor from '../../../../assets/js/ckeditor.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'certificate-form',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  public ckEditor: any = CustomEditor;
  public config_cf_message: any = {
    placeholder: this.translate.instant('forms.certificate.app_message_body')
  }
  public config_cf_pdf_text: any = {
    placeholder: this.translate.instant('forms.certificate.pdf_message_body')
  }
  public model: any = {
    cf_message: "",
    cf_pdf_text: ""
  }
  public disableInputs: boolean = false;

  public certificateForm: FormGroup;
  @Input() public part: Part;
  @Input() public expired: boolean;
  public enableSaveButton: boolean = false;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {
    this.model.cf_message = this.part.settings.cf_message;
    this.model.cf_pdf_text = this.part.settings.cf_pdf_text;
    this.buildForm();
    if(this.expired){
      this.certificateForm.disable();
      this.disableInputs = true;
    }

  }

  public informationChanged() {
    this.patchPart.emit(this.model);
  }

  private buildForm(): void {
    this.certificateForm = this.formBuilder.group({
      cf_has_custom_image: [this.part.settings.cf_has_custom_image, [Validators.required]],
      cf_only_name: [this.part.settings.cf_only_name, [Validators.required]],
      cf_show_points: [this.part.settings.cf_show_points, [Validators.required]],
      cf_show_results: [this.part.settings.cf_show_results, [Validators.required]],
      cf_show_nothing: [this.part.settings.cf_show_nothing, [Validators.required]],
      cf_custom_image_url: [this.part.settings.cf_custom_image_url, []],
    });

    setTimeout(() => {
      this.certificateForm.valueChanges.subscribe(() => {
        if(this.certificateForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)
  }

  public patchPartFromParent(includeImage: boolean): void {
    let valuesToUpdate = Object.assign({}, this.certificateForm.value);
    if(!includeImage)
      delete valuesToUpdate.cf_custom_image_url;

    this.patchPart.emit(valuesToUpdate);
  }

  public previewImage(event): void {
    if(event.target && event.target.files && event.target.files.length > 0) {
      const dialogRef = this.dialog.open(UploadPhoto, {
        width: '600px',
        data: {
          title: this.translate.instant('forms.image_pop_up_body'),
          circle: false,
          maintain_aspect_ratio: true,
          aspect_ratio_1: 620,
          aspect_ratio_2: 877,
          event: event
        }
      });
      dialogRef.afterClosed().subscribe((data) => {
        if(data && data.img) {
          this.certificateForm.get('cf_custom_image_url').setValue(data.img);
          this.patchPartFromParent(true);
        }
      });
    }
  }

}
