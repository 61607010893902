import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;
  public showThanks: boolean = false;
  public isAuthenticated: boolean = false;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private aApi: AuthService) {
    // Sales contact en helpdesk contact //
  }

  ngOnInit() {
    this.buildForm();
    this.isAuthenticated = this.aApi.isAuthenticated();
    if(!this.isAuthenticated) {
      this.contactForm.get('email').setValidators([Validators.email, Validators.required]);
      this.contactForm.get('email').updateValueAndValidity();
    }
  }

  private buildForm(): void {
    this.contactForm = this.formBuilder.group({
      email: ["", [Validators.email]],
      type: ["help", [Validators.required]],
      message: ["", [Validators.required]]
    });
  }

  public onSubmit(values: any): void{
    this.api.postContact(values).then(result => {
      if(result['status'] == "successful") {
        this.showThanks = true;
        this.contactForm.reset();
      }
    }).catch(error => {
      console.log(error);
      alert("Something went wrong");
    });
  }

}
