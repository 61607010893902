import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  private verifyHash: string;
  public loading: boolean = true;
  public hashVerified: boolean = false;
  public hashError: boolean = false;
  public alreadyVerified: boolean = false;

  constructor(private route: ActivatedRoute, private aService: AuthService) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
       this.verifyHash = params.hash;
       this.aService.verifyEmail({hash: this.verifyHash}).then(result => {
         this.loading = false;
         if(result['status'] == 'successful')
          this.hashVerified = true;
         else if(result['status'] == "already_verified")
          this.alreadyVerified = true;
         else
          this.hashError = true;
       }).catch(error => {
         console.log(error);
         alert("Something went wrong");
       });
    });
  }

}
