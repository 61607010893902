import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'delete-account_dialog',
  templateUrl: './delete-account.dialog.html',
  styleUrls: ['./delete-account.dialog.scss']
})
export class DeleteAccountDialog implements OnInit {

  public title: string;
  public content: string;
  public confirmButton: string;
  public cancelButton: string;
  public showCancel: boolean;
  public understoodCheck: boolean = false;

  constructor(public dialogRef: MatDialogRef<DeleteAccountDialog>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = this.data.title;
    this.confirmButton = this.data.confirmButton;
    this.cancelButton = this.data.cancelButton;
    this.content = this.data.content ? this.data.content : undefined;
    this.showCancel = this.data.showCancel;
  }

  ngOnInit() {

  }

  public confirm() {
    this.dialogRef.close({confirm: 1});
  }

  public dismiss() {
    this.dialogRef.close({confirm: 0});
  }

  public understood() {
    this.understoodCheck = true;
  }

}
