import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'beacon-form',
  templateUrl: './beacon.component.html',
  styleUrls: ['./beacon.component.scss']
})
export class BeaconForm implements OnInit {

  public beaconForm: FormGroup;
  @Input() public part: Part;
  @Input() public expired: boolean;
  public enableSaveButton: boolean = false;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  public beaconChoices: any = [{
    id: "far",
    title: this.translate.instant('forms.beacon.far')
  }, {
    id: "near",
    title: this.translate.instant('forms.beacon.near')
  }, {
    id: "immediate",
    title: this.translate.instant('forms.beacon.immediate')
  }]

  constructor(private formBuilder: FormBuilder, private translate: TranslateService) {

  }

  ngOnInit() {
    this.buildForm();
    this.setValidators(this.part.settings.bc_activate_beacon);

    if(this.expired)
      this.beaconForm.disable();
  }

  private buildForm(): void {
    this.beaconForm = this.formBuilder.group({
      bc_activate_beacon: [this.part.settings.bc_activate_beacon, [Validators.required]],
      bc_beacon_mandatory: [this.part.settings.bc_beacon_mandatory, [Validators.required]],
      bc_beacon_behaviour: [this.part.settings.bc_beacon_behaviour, [Validators.required]],
      bc_beacon_major: [this.part.settings.bc_beacon_major, [Validators.required, Validators.maxLength(155)]],
      bc_beacon_minor: [this.part.settings.bc_beacon_major, [Validators.required, Validators.maxLength(155)]]
    });

    this.beaconForm.get('bc_activate_beacon').valueChanges.subscribe(value => {
      this.setValidators(value);
    });

    setTimeout(() => {
      this.beaconForm.valueChanges.subscribe(() => {
        if(this.beaconForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)

  }

  private setValidators(value: boolean): void {
    if(value) {
      this.beaconForm.get('bc_beacon_major').setValidators([Validators.required]);
      this.beaconForm.get('bc_beacon_minor').setValidators([Validators.required]);
    } else {
      this.beaconForm.get('bc_beacon_major').setValidators([]);
      this.beaconForm.get('bc_beacon_minor').setValidators([]);
    }

    this.beaconForm.get('bc_beacon_major').updateValueAndValidity();
    this.beaconForm.get('bc_beacon_minor').updateValueAndValidity();
  }

  public patchPartFromParent(): void {
    this.patchPart.emit(this.beaconForm.value);
  }
}
