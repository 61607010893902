import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiURL: string = environment.apiUrl;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {

  }

  public isAuthenticated(): boolean {
    let authenticated = this.getToken() && !this.isExpired() ? true : false;
    return authenticated;
  }

  public getToken(): string {
    return this.jwtHelper.tokenGetter();
  }

  private isExpired(): boolean {
    return this.jwtHelper.isTokenExpired();
  }

  public getExpireDate(): any {
    return this.jwtHelper.getTokenExpirationDate();
  }

  public decodeToken(): any {
    return this.jwtHelper.decodeToken();
  }

  public getUser() {
    return new Promise(resolve => {
			this.http.get(this.apiURL + "getUser")
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
	  	});
  }

  public getTestDevices() {
    return new Promise(resolve => {
			this.http.get(this.apiURL + "getTestDevices")
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
	  	});
  }

  public authenticate(data: any) {
    return new Promise(resolve => {
			this.http.post(this.apiURL + "login", data)
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
	  	});
  }

  public register(data: any) {
    return new Promise(resolve => {
			this.http.post(this.apiURL + "register", data)
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
	  	});
  }

  public verifyEmail(data: any) {
    return new Promise(resolve => {
			this.http.post(this.apiURL + "verifyEmail", data)
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
	  	});
  }

  public forgotPassword(data: any) {
    return new Promise(resolve => {
			this.http.post(this.apiURL + "forgotPassword", data)
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
	  	});
  }

  public changePassword(data: any) {
    return new Promise(resolve => {
			this.http.post(this.apiURL + "changePassword", data)
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
	  	});
  }

  public resetPassword(data: any) {
    return new Promise(resolve => {
			this.http.post(this.apiURL + "resetPassword", data)
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
	  	});
  }

  public invalidateToken() {
    return new Promise(resolve => {
  		this.http.post(this.apiURL + "logout", {})
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public logout() {
    return new Promise(resolve => {
      this.invalidateToken().then(() => {
        resolve();
      });
    });
  }

}
