import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-avg',
  templateUrl: './avg.component.html',
  styleUrls: ['./avg.component.scss']
})
export class AvgComponent implements OnInit {

  public contactForm: FormGroup;
  public showThanks: boolean = false;
  public isAuthenticated: boolean = false;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private aApi: AuthService) {
    // Sales contact en helpdesk contact //
  }

  ngOnInit() {
  }
}
