import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'results-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailResults implements OnInit {

  public tour: any = [];
  public result: any = [];
  public parts: any = [];
  public loader: boolean = false;
  public expired: boolean;
  public warning: boolean;
  public free: boolean;

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.free = this.route.snapshot.data.result.expire_data.free;
    this.expired = this.route.snapshot.data.result.expire_data.expired;
    this.warning = this.route.snapshot.data.result.expire_data.warning;

    this.tour = this.route.snapshot.data.result.results.tour || [];
    this.parts = this.route.snapshot.data.result.results.device_tour_parts || [];
    this.result = this.route.snapshot.data.result.results || [];
  }
}
