import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  private hash: string;
  public resetPasswordForm: FormGroup;
  public showInvalid: boolean = false;
  public showExpired: boolean = false;
  public errors: any;
  public showResetted: boolean = false;

  constructor(private route: ActivatedRoute, private aService: AuthService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.hash = params.hash;
      this.buildForm();
    });
  }

  private buildForm(): void {
    this.resetPasswordForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.minLength(6)]],
      password_repeat: ["", [Validators.required, Validators.minLength(6)]]
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  public onSubmit(values): void {
    values.hash = this.hash;
    this.aService.resetPassword(values).then(result => {

      this.showInvalid = false;
      this.showExpired = false;
      this.errors = undefined;
      this.showResetted = false;

      if(result['error'] || result['status'] == 'error') {
        if(result['type'] && result['type'] == 'invalid')
          this.showInvalid = true;
        if(result['type'] && result['type'] == 'expired')
          this.showExpired = true;
        if(result['error'] && result['error'].errors && result['status'] && result['status'] == 422)
          this.errors = result['error'].errors;
        
        this.resetPasswordForm.reset();
      } else if(result['status'] == 'successful') {
        this.showResetted = true;
        this.resetPasswordForm.reset();
      }
    });
  }

}

export class PasswordValidation {

  static MatchPassword(AC: AbstractControl) {
    let password = AC.get('password').value; // to get value in input tag
    let confirmPassword = AC.get('password_repeat').value; // to get value in input tag
    if(password != confirmPassword)
      AC.get('password_repeat').setErrors( { MatchPassword: true } );
    else
      return null;
  }
}
