import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private apiURL: string = environment.apiUrl;

  constructor(private http: HttpClient) {

  }

  public getAllUsers() {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getAllUsers")
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public switchToUserAsAdmin(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "switchToUserAsAdmin", data)
        .subscribe(data => {
           resolve(data);
        }, error => {
           resolve(error);
        });
    });
  }
}
