import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TourService } from '../../../../service/tour.service';
import { ResizeEvent } from 'angular-resizable-element';
import { ConfirmDialog } from '../../../../dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clickmap-tour',
  templateUrl: './clickmap.component.html',
  styleUrls: ['./clickmap.component.scss']
})
export class ClickmapComponentTour implements OnInit {

  public settingsOfTour: any;
  public tourID: string;
  public enableSaveButton: boolean = false;
  public clickableMapFormGroup: FormGroup;

  public expired: boolean;
  public showForm: boolean = false;
  public loading: boolean = true;
  public clickAreas: any = [];

  public listOfParts: any = [];

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private tApi: TourService, private dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {
    this.tourID = this.route.snapshot.data.settings.tourID;
    this.settingsOfTour = this.route.snapshot.data.settings.settings;
    this.expired = this.route.snapshot.data.settings.expire_data.expired;

    this.getClickableAreas();
    this.getParts();
    setTimeout(() => {

      if(!this.settingsOfTour.ls_has_clickable_map || this.expired)
        this.router.navigate(["/creator/tours/" + this.tourID + "/settings"]);
      else {
        this.showForm = true;
        this.buildForm();
      }
    }, 50)
  }

  private getParts(): void {
    this.tApi.getTourParts(this.tourID).then((result: any) => {
      this.listOfParts = result.parts;
    });
  }

  public updateTour(): void {
    let data = {
      tourID: this.tourID,
      value: this.clickableMapFormGroup.value
    }

    this.tApi.updateTourSettings(data);
  }

  private getClickableAreas(): void {
    this.tApi.getClickAreas(this.tourID).then((result: any) => {
      this.loading = false;
      this.clickAreas = [];
      result.clickareas.forEach((value: any) => {
        value.init_x = value.x;
        value.init_y = value.y;
        value.enable_save = false;
        this.clickAreas.push(value);
      });
    });
  }

  public onResizeEnd(event: ResizeEvent, clickarea: any): void {
    clickarea.width = event.rectangle.width + 20; // adding the padding difference
    clickarea.height = event.rectangle.height + 20; // adding the padding difference
    this.updateClickableArea(clickarea.id, clickarea.x, clickarea.y, clickarea.width, clickarea.height);
  }

  public addClickableArea(): void {
    this.tApi.createNewClickArea(this.tourID, {
      x: 50,
      y: 50,
      width: 150,
      height: 150,
      color: "#0000FF"
    }).then((result: any) => {
      if(result && result.status == "successful" && result.clickarea) {
        result.clickarea.init_x = result.clickarea.x;
        result.clickarea.init_y = result.clickarea.y;
        result.clickarea.enable_save = false;
        this.clickAreas.push(result.clickarea)
      }
    });
  }

  public updateClickableArea(clickableMapID: string, x: number, y: number, width: number, height: number): void {
    this.tApi.updateClickableArea(this.tourID, {
      x: x,
      y: y,
      width: width,
      height: height,
      id: clickableMapID
    }).then(() => {
      this.getClickableAreas();
    });
  }

  public onDragEnded(event: any) {
    let object = this.clickAreas.find((element: any) => element.id == event.source.getRootElement().id);
    let offset =  { ...(<any>event.source._dragRef)._passiveTransform };

    object.x = object.init_x + offset.x;
    object.y = object.init_y + offset.y;

    this.updateClickableArea(object.id, object.init_x + offset.x, object.init_y + offset.y, object.width, object.height);
  }


  public updateClickAreaNavigation(clickArea: any): void {
    this.tApi.updateClickableAreaNavigation(this.tourID, clickArea.id, {
      color: clickArea.color,
      popup_title: clickArea.popup_title,
      popup_content: clickArea.popup_content,
      has_jump: clickArea.has_jump,
      to_popup: clickArea.to_popup,
      part_id: clickArea.part_id
    });
  }

  public dialogChanged(event: any, clickArea: any) {
    if(!event) {
      this.updateClickAreaNavigation(clickArea);
      clickArea.enable_save = true;
    }
  }

  public deleteClickarea(clickArea: any, index: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('forms.clickmap.delete_pop_up_body'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.tApi.deleteClickarea(this.tourID, clickArea.id).then(() => {
          this.clickAreas.splice(index, 1);
        }).catch(error => {
          console.log(error);
          alert("Something went wrong");
        });
      }
    });
  }

  public showTileNumber(key: string): number {
    return parseInt(key) + 1;
  }

  private buildForm(): void {
    this.clickableMapFormGroup = this.formBuilder.group({
      ls_clicktiles_show_border: [this.settingsOfTour.ls_clicktiles_show_border, [Validators.required]]
    });
  }

  public saveButton(){
    this.enableSaveButton = false;
    this.router.navigate(["/creator/tours/" + this.tourID + "/settings"]);
  }
}
