import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Part } from '../../../interfaces/part';
import { PartService } from '../../../service/part.service';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'media-form',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaForm implements OnInit {

  @Input() public part: Part;
  @Input() public tourID: string;
  @Input() public expired: boolean;
  public mediaForm: FormGroup;
  public showSpinner: boolean = false;

  public errorVideo: boolean = false;
  public errorAudio: boolean = false;

  public maxFileSize: number = 25;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private pApi: PartService, private dialog: MatDialog, private translate: TranslateService, private formBuilder: FormBuilder) {  }
  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    
    this.mediaForm = this.formBuilder.group({
      md_audio: [this.part.settings.md_audio, []],
      md_audio_autoplay: [this.part.settings.md_audio_autoplay, []],
      md_audio_video: [this.part.settings.md_audio_video, [Validators.required]],
      md_video: [this.part.settings.md_video, []],
      md_video_autoplay: [this.part.settings.md_video_autoplay, []],
      md_video_type: [this.part.settings.md_video_type, []],
      md_youtube: [this.part.settings.md_youtube, [Validators.pattern('.*youtu.*embed.*')]]
    });
    
    if(this.expired)
      this.mediaForm.disable();
  }

  public patchPartFromParentYT(): void {
    if(this.mediaForm.get('md_youtube').valid || this.mediaForm.get('md_youtube').value == ''){
      this.patchPartFromParent();
    }else{
      alert('Youtube link not valid');
    }
  }

  public patchPartFromParent(): void {
    const values: any = this.mediaForm.value;
    delete values.md_audio;
    delete values.md_video;
    this.patchPart.emit(values);
  }

  public deleteAudio(): void {
    this.errorAudio = false;
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('forms.media.dialog_1') + "audio " + this.translate.instant('forms.media.dialog_2'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.showSpinner = true;
        let data = {
          type: "audio",
          tourID: this.tourID,
          partID: this.part.id
        }
        this.pApi.deleteMedia(data).then(() => {
          this.mediaForm.get('md_audio').setValue(null);
          this.showSpinner = false;
        }).catch(error => {
          console.log(error);
          alert("Something went wrong");
        });
      }
    });
  }

  public uploadAudio(event: any): void {
    this.showSpinner = true;
    this.errorAudio = false;
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result.toString();
        let data = {
          filename: file.name,
          filetype: file.type,
          value: result.split(',')[1],
          type: "audio",
          tourID: this.tourID,
          partID: this.part.id
        }

        if((file.size / 1000000 /* conversion to MB */) > this.maxFileSize) {
          this.errorAudio = true;
          this.showSpinner = false;
        } else {
          this.pApi.uploadMedia(data).then(result => {
            this.mediaForm.get('md_audio').setValue(result['url']);
            this.showSpinner = false;
          }).catch(error => {
            console.log(error);
          });
        }
      };
    } else {
      this.showSpinner = false;
    }
  }

  public deleteVideo(): void {
    this.errorVideo = false;
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('forms.media.dialog_1') + "video " + this.translate.instant('forms.media.dialog_2'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.showSpinner = true;
        let data = {
          type: "video",
          tourID: this.tourID,
          partID: this.part.id
        }
        this.pApi.deleteMedia(data).then(() => {
          this.mediaForm.get('md_video').setValue(null);
          this.showSpinner = false;
        }).catch(error => {
          console.log(error);
          alert("Something went wrong");
        });
      }
    });
  }

  public uploadVideo(event: any): void {
    this.showSpinner = true;
    this.errorVideo = false;
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result.toString();
        let data = {
          filename: file.name,
          filetype: file.type,
          value: result.split(',')[1],
          type: "video",
          tourID: this.tourID,
          partID: this.part.id
        }

        if((file.size / 1000000 /* conversion to MB */) > this.maxFileSize) {
          this.errorVideo = true;
          this.showSpinner = false;
        } else {
          this.pApi.uploadMedia(data).then(result => {
            this.mediaForm.get('md_video').setValue(result['url']);
            this.showSpinner = false;
          }).catch(error => {
            console.log(error);
          });
        }
      };
    } else {
      this.showSpinner = false;
    }
  }
}
