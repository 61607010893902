import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartService } from '../../../service/part.service';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
import { CopyPartDialog } from '../../../dialogs/copy-part/copy-part.dialog';
import { PickPartTypeDialog } from '../../../dialogs/pick-part-type/pick-part-type.dialog';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'tours-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class ToursOverview implements OnInit {

  public parts: any[];
  public expired: boolean;
  public tourID: string;
  public partsOrdered: any = [];
  public loader: boolean = false;

  constructor(private route: ActivatedRoute, private pApi: PartService, private dialog: MatDialog, private router: Router, private translate: TranslateService) {

  }

  ngOnInit() {
    this.tourID = this.route.snapshot.data.parts[1];
    this.parts = this.route.snapshot.data.parts[0].parts;
    this.expired = this.route.snapshot.data.parts[0].expire_data.expired;
    this.parts.forEach((value, key) => {
      value.index = key;
    });
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.parts, event.previousIndex, event.currentIndex);
    this.pApi.updatePartOrder(this.tourID, this.parts);
  }

  public createPart(): void {
    const dialogRef = this.dialog.open(PickPartTypeDialog, {
      data: {
        title: this.translate.instant('dialogs.pickPart.title'),
        tourID: this.tourID
      },
      width: '350px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.tourID) {
        this.loader = true;
        this.pApi.createPart(data).then(result => {
          this.router.navigate(["/creator/tours/" + this.tourID + "/parts/" + result['partID']]);
        }).catch(error => {
          console.log(error);
          this.loader = false;
          alert("Something went wrong");
        });
      }
    });
  }

  public copyPart(part: any) {
    const dialogRef = this.dialog.open(CopyPartDialog, {
      data: {
        tourID: this.tourID,
        part: part
      },
      width: '350px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.tour) {
        this.loader = true;
        let submitData = {
          target_tour: data.tour,
          part: part.id,
          current_tour: this.tourID
        }

        this.pApi.copyPart(submitData).then((data: any) => {
          this.loader = false;
          if(submitData.target_tour == this.tourID)
            this.parts.unshift(data.new_part);
        }).catch(error => {
          console.log(error);
          this.loader = false;
          alert("Something went wrong");
        });
      }
    });
  }

  public deletePart(part): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('dialogs.delete_part.title'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.loader = true;
        this.pApi.deletePart(this.tourID, part.id).then(() => {
          this.loader = false;
          this.parts.splice(part.index, 1);
          this.parts.forEach((value, key) => {
            value.index = key;
          });
        }).catch(error => {
          console.log(error);
          this.loader = false;
          alert("Something went wrong");
        });
      }
    });
  }

}
