import { Injectable } from '@angular/core';
import { PartService } from '../service/part.service';
import { TourService } from '../service/tour.service';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetPartService implements Resolve<any> {

  constructor(private pApi: PartService, private tApi: TourService) {

  }

  resolve(route: ActivatedRouteSnapshot) {

    let tourID = route.paramMap.get('tourid');
    let partID = route.paramMap.get('partid');

    // for clickable map and clickable tiles
    if(!tourID)
      tourID = route.parent.paramMap.get('tourid');
    if(!partID)
      partID = route.parent.paramMap.get('partid');

    return forkJoin(
      this.pApi.getPart(tourID, partID).then(result => {
        if(result['status'] == 'successful')
          return result;
        else
          return {};
      }).catch(error => {
        // do some logging
        console.log(error);
        return {};
      }),
      this.tApi.getTourSettings(tourID).then(result => {
        if(result['status'] == 'successful')
          return result;
        else
          return {};
      }).catch(error => {
        // do some logging
        console.log(error);
        return {};
      })
    );
  }
}
