import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UploadPhoto } from '../../../dialogs/upload_photo/upload_photo.dialog';
import { MatDialog } from '@angular/material';
import { TourService } from '../../../service/tour.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tours-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class ToursSettings implements OnInit {

  public elementType: 'url' | 'canvas' | 'img' = 'url';
  public qrvalue: string;

  public tourSettings: any = {
    general: {
      tourprogress: [{
        id: "static_with_partdescription",
        title: this.translate.instant('forms.settings.controller.static_with_partdescription')
      }, {
        id: "static_with_hidden_partdescription",
        title: this.translate.instant('forms.settings.controller.static_with_hidden_partdescription')
      }, {
        id: "random",
        title: this.translate.instant('forms.settings.controller.random')
      }],
      show_access_code: [{
        id: "before_download",
        title: this.translate.instant('forms.settings.controller.before_download')
      }, {
        id: "after_download",
        title: this.translate.instant('forms.settings.controller.after_download')
      }]
    },
    location: {
      maptype: [{
        id: "no_map",
        title: this.translate.instant('forms.settings.controller.no_map')
      }, {
        id: "gps_map",
        title: this.translate.instant('forms.settings.controller.gps_map')
      }, {
        id: "image_map",
        title: this.translate.instant('forms.settings.controller.image_map')
      }]
    }
  };

  private tourID: string;

  public settingsOfTour: any;
  public enableSaveButton: boolean = false;

  public expired: boolean;

  /* GENERAL SETTINGS */
  public generalSettings: FormGroup;
  public showAccessPart: boolean = false;
  public showEmail: boolean = false;
  public showBeacon: boolean = false;

  /* LOCATION SETTINGS */
  public locationSettings: FormGroup;
  public showSelectImage: boolean = false;
  public hasImage: boolean = false;

  /* POINTS SETTINGS */
  public pointsSettings: FormGroup;
  public showPointsBlock: boolean = false;
  public showJokersBlock: boolean = false;

  public qrCode: string = '';

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private tApi: TourService, public route: ActivatedRoute, private translate: TranslateService) {

  }

  ngOnInit() {
    this.tourID = this.route.snapshot.data.settings.tourID;
    this.settingsOfTour = this.route.snapshot.data.settings.settings;
    this.expired = this.route.snapshot.data.settings.expire_data.expired;
    this.qrvalue = this.route.snapshot.data.settings.tourcode;

    this.buildForm();
    this.configureGeneralSettings();
    this.configureLocationSettings();
    this.configurePointsSettings();

    if(this.expired) {
      this.generalSettings.disable();
      this.locationSettings.disable();
      this.pointsSettings.disable();
    }
    let postData = {
      cht: 'qr',
      chs: '500x500',
      chl: this.route.snapshot.data.settings.tourcode
    }
    this.qrCode= "https://api.qrserver.com/v1/create-qr-code/?size= " + postData.chs +  " &data=" + postData.chl;
    // this.qrCode= "https://chart.googleapis.com/chart?cht=" + postData.cht + "&chs=" + postData.chs + "&chl=" + postData.chl;
  }

  private configureGeneralSettings(): void {
    if(this.settingsOfTour.gs_has_access_code)
      this.accessCodeChanged();
    if(this.settingsOfTour.gs_show_email)
      this.emailChanged();
    if(this.settingsOfTour.gs_beacon_behavior !== "off")
      this.beaconChanged();
  }

  private configureLocationSettings(): void {
    this.maptypeChanged();
    if(this.settingsOfTour['ls_map_image_path'] && this.settingsOfTour['ls_map_image_path'] !== "")
      this.hasImage = true;
  }

  private configurePointsSettings(): void {
    if(this.settingsOfTour.ps_points_enabled)
      this.pointsChanged();
  }

  /* GENERAL SETTINGS */
  public accessCodeChanged(): void {
    this.showAccessPart = !this.showAccessPart;
    if(this.showAccessPart)
      this.generalSettings.get('gs_access_code').setValidators([Validators.required]);
    else
      this.generalSettings.get('gs_access_code').setValidators([]);
  }

  public emailChanged(): void {
    this.showEmail = !this.showEmail;
    if(this.showEmail)
      this.generalSettings.get('gs_email').setValidators([Validators.required]);
    else
      this.generalSettings.get('gs_email').setValidators([]);
  }

  public beaconChanged(): void {
    if(!this.generalSettings.get('gs_beacon_enabled').value) {
      this.generalSettings.get('gs_beacon_uuid').setValidators([]);
      this.showBeacon = false;
    } else {
      this.generalSettings.get('gs_beacon_uuid').setValidators([Validators.required]);
      this.showBeacon = true;
    }
    this.generalSettings.get('gs_beacon_uuid').updateValueAndValidity();
  }

  /* LOCATION SETTINGS */
  public maptypeChanged(): void {
    let maptype = this.locationSettings.get('ls_map_type').value;
    if(maptype == "image_map") {
      this.showSelectImage = true;
      this.locationSettings.get('ls_map_image').setValidators([Validators.required]);
    } else {
      this.showSelectImage = false;
      this.locationSettings.get('ls_map_image').setValidators([]);
    }
  }

  public previewImage(event: any): void {
    if(event.target && event.target.files && event.target.files.length > 0) {
      const dialogRef = this.dialog.open(UploadPhoto, {
        width: '600px',
        data: {
          title: "Verander locatieafbeelding",
          circle: false,
          maintain_aspect_ratio: false,
          aspect_ratio_1: 3,
          aspect_ratio_2: 4,
          event: event
        }
      });
      dialogRef.afterClosed().subscribe((data) => {
        if(data && data.img) {
          this.hasImage = true;
          this.locationSettings.get('ls_map_image').setValue(data.img);
          this.updateTour(1);
        }
      });
    }
  }

  // POINTS SETTINGS
  public pointsChanged(): void {
    this.showPointsBlock = !this.showPointsBlock;
    if(this.showPointsBlock)
      this.pointsSettings.get('ps_jokers_enabled').setValidators([Validators.required]);
    else
      this.pointsSettings.get('ps_jokers_enabled').setValidators([]);

    if(this.pointsSettings.get('ps_jokers_enabled').value && this.pointsSettings.get('ps_points_enabled').value)
      this.showJokersBlock = true;
    else
      this.showJokersBlock = false;
  }

  public jokerChanged(): void {
    this.showJokersBlock = !this.showJokersBlock;
    if(this.showJokersBlock)
      this.pointsSettings.get('ps_amount_of_jokers').setValidators([Validators.required, Validators.min(1)]);
    else
      this.pointsSettings.get('ps_amount_of_jokers').setValidators([]);
  }


  public updateTour(form: number): void {
    let data = {
      tourID: this.tourID,
      value: null
    }

    switch(form) {
      case 0:
        data.value = this.generalSettings.value;
        break;
      case 1:
        data.value = this.locationSettings.value;
        if(this.locationSettings.get('ls_map_image').value && (this.locationSettings.get('ls_map_image').value.includes('http://') || this.locationSettings.get('ls_map_image').value.includes('https://')))
          delete data.value.ls_map_image;
        break;
      case 2:
        data.value = this.pointsSettings.value;
        break;
      default:
        break;
    }

    this.tApi.updateTourSettings(data);
  }

  private buildForm(): void {
    /* GENERAL SETTINGS */
    this.generalSettings = this.formBuilder.group({
      gs_tour_progress: [this.settingsOfTour.gs_tour_progress, [Validators.required]],
      gs_copyable: [this.settingsOfTour.gs_copyable, [Validators.required]],
      gs_has_access_code: [this.settingsOfTour.gs_has_access_code, [Validators.required]],
      gs_access_code: [this.settingsOfTour.gs_access_code, []],
      gs_show_access_code: [this.settingsOfTour.gs_show_access_code, [Validators.required]],
      gs_show_email: [this.settingsOfTour.gs_show_email, [Validators.required]],
      gs_email: [this.settingsOfTour.gs_email, [Validators.email]],
      gs_wifi_required: [this.settingsOfTour.gs_wifi_required, [Validators.required]],
      gs_tour_online: [this.settingsOfTour.gs_tour_online, [Validators.required]],
      gs_beacon_enabled: [this.settingsOfTour.gs_beacon_enabled, [Validators.required]],
      gs_beacon_uuid: [this.settingsOfTour.gs_beacon_uuid, []],
      gs_active: [this.settingsOfTour.gs_active, []],
      gs_request_name: [this.settingsOfTour.gs_request_name, []],
      gs_request_name_text: [this.settingsOfTour.gs_request_name_text, []]
    });
    /* LOCATION SETTINGS */
    this.locationSettings = this.formBuilder.group({
      ls_map_type: [this.settingsOfTour.ls_map_type, [Validators.required]],
      ls_map_image: [this.settingsOfTour.ls_map_image, []],
      ls_link_coordinates: [{value: this.settingsOfTour.ls_link_coordinates, disabled: true}, []],
      ls_has_clickable_map: [this.settingsOfTour.ls_has_clickable_map, []],
      ls_custom_style: [this.settingsOfTour.ls_custom_style, []],
      ls_custom_apikey: [this.settingsOfTour.ls_custom_apikey, []]
    });
    /* POINTS SETTINGS */
    this.pointsSettings = this.formBuilder.group({
      ps_points_enabled: [this.settingsOfTour.ps_points_enabled, [Validators.required]],
      ps_jokers_enabled: [this.settingsOfTour.ps_jokers_enabled, []],
      ps_amount_of_jokers: [this.settingsOfTour.ps_amount_of_jokers, []]
    });

    this.generalSettings.valueChanges.subscribe(() => {
      if(this.generalSettings.valid)
        this.enableSaveButton = true;
      else
        this.enableSaveButton = false;
    });

    this.locationSettings.valueChanges.subscribe(() => {
      if(this.generalSettings.valid)
        this.enableSaveButton = true;
      else
        this.enableSaveButton = false;
    });

    this.pointsSettings.valueChanges.subscribe(() => {
      if(this.generalSettings.valid)
        this.enableSaveButton = true;
      else
        this.enableSaveButton = false;
    });
  }
}
