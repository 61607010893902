import { Component, OnInit } from '@angular/core';
import { Part } from '../../../interfaces/part';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartService } from '../../../service/part.service';
import { TourService } from '../../../service/tour.service';
import { ResizeEvent } from 'angular-resizable-element';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-clickmap',
  templateUrl: './clickmap.component.html',
  styleUrls: ['./clickmap.component.scss']
})
export class ClickmapComponent implements OnInit {

  public part: Part;
  public tourID: string;
  public enableSaveButton: boolean = false;
  public clickableMapFormGroup: FormGroup;

  public expired: boolean;
  public showForm: boolean = false;
  public loading: boolean = true;
  public clickAreas: any = [];

  public listOfParts: any = [];

  constructor(private translate: TranslateService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private pApi: PartService, private tApi: TourService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.part = this.route.snapshot.data.part[0].part;
    this.expired = this.route.snapshot.data.part[0].expired;
    this.tourID = this.route.snapshot.data.part[1].tourID;

    this.getClickableAreas();
    this.getParts();
    setTimeout(() => {
      if(!this.part.settings.ls_has_clickable_map || this.expired)
        this.router.navigate(["/creator/tours/" + this.tourID + "/parts/" + this.part.id]);
      else {
        this.showForm = true;
        this.buildForm();
      }
    }, 50)

  }

  private getParts(): void {
    this.tApi.getTourParts(this.tourID).then((result: any) => {
      this.listOfParts = result.parts;
    });
  }

  private getClickableAreas(): void {
    this.pApi.getClickAreas(this.tourID, this.part.id).then((result: any) => {
      this.loading = false;
      this.clickAreas = [];
      result.clickareas.forEach((value: any) => {
        value.init_x = value.x;
        value.init_y = value.y;
        value.enable_save = false;
        this.clickAreas.push(value);
      });
    });
  }

  public onResizeEnd(event: ResizeEvent, clickarea: any): void {
    clickarea.width = event.rectangle.width + 20; // adding the padding difference
    clickarea.height = event.rectangle.height + 20; // adding the padding difference
    this.updateClickableArea(clickarea.id, clickarea.x, clickarea.y, clickarea.width, clickarea.height);
  }

  public addClickableArea(): void {
    this.pApi.createNewClickArea(this.tourID, this.part.id, {
      x: 50,
      y: 50,
      width: 150,
      height: 150,
      color: "#0000FF"
    }).then((result: any) => {
      if(result && result.status == "successful" && result.clickarea) {
        result.clickarea.init_x = result.clickarea.x;
        result.clickarea.init_y = result.clickarea.y;
        result.clickarea.enable_save = false;
        this.clickAreas.push(result.clickarea)
      }
    });
  }

  public updateClickableArea(clickableMapID: string, x: number, y: number, width: number, height: number): void {
    this.pApi.updateClickableArea(this.tourID, this.part.id, {
      x: x,
      y: y,
      width: width,
      height: height,
      id: clickableMapID
    }).then(() => {
      this.getClickableAreas();
    });
  }

  public onDragEnded(event: any) {
    let object = this.clickAreas.find((element: any) => element.id == event.source.getRootElement().id);
    let offset =  { ...(<any>event.source._dragRef)._passiveTransform };

    object.x = object.init_x + offset.x;
    object.y = object.init_y + offset.y;

    this.updateClickableArea(object.id, object.init_x + offset.x, object.init_y + offset.y, object.width, object.height);
  }

  public patchPart() {
    this.enableSaveButton = true;
    let valuesToUpdate = Object.assign({}, this.clickableMapFormGroup.value);

    let postData = {
      tourID: this.tourID,
      partID: this.part.id,
      values: valuesToUpdate
    }

    // save to database
    this.pApi.patchPartSettings(postData).then(() => {
      // patch values so that all tabs have the updated object
      let valuesToPatch = Object.keys(postData.values);
      valuesToPatch.forEach(value => {
        this.part.settings[value] = this.clickableMapFormGroup.value[value];
      });
    });
  }

  public updateClickAreaNavigation(clickArea: any): void {
    this.pApi.updateClickableAreaNavigation(this.tourID, this.part.id, clickArea.id, {
      color: clickArea.color,
      popup_title: clickArea.popup_title,
      popup_content: clickArea.popup_content,
      has_jump: clickArea.has_jump,
      to_popup: clickArea.to_popup,
      part_id: clickArea.part_id
    });
  }

  public dialogChanged(event: any, clickArea: any) {
    if(!event) {
      this.updateClickAreaNavigation(clickArea);
      clickArea.enable_save = true;
    }
  }

  public deleteClickarea(clickArea: any, index: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('forms.clickmap.delete_pop_up_body'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.pApi.deleteClickarea(this.tourID, this.part.id, clickArea.id).then(() => {
          this.clickAreas.splice(index, 1);
        }).catch(error => {
          console.log(error);
          alert("Something went wrong");
        });
      }
    });
  }

  public showTileNumber(key: string): number {
    return parseInt(key) + 1;
  }

  private buildForm(): void {
    this.clickableMapFormGroup = this.formBuilder.group({
      ls_clicktiles_show_border: [this.part.settings.ls_clicktiles_show_border, [Validators.required]]
    });
  }

  public saveButton(){
    this.enableSaveButton = false;
    this.router.navigate(["/creator/tours/" + this.tourID + "/parts/" + this.part.id]);
  }
}
