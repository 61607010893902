import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import * as CustomEditor from '../../../../assets/js/ckeditor.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'opinion-form',
  templateUrl: './opinion.component.html',
  styleUrls: ['./opinion.component.scss']
})
export class OpinionComponent implements OnInit {
  public ckEditor: any = CustomEditor;
  public disableInputs: boolean = false;
  public config_op_message: any = {
    placeholder: this.translate.instant('forms.opinion.question')
  }
  public config_op_message_after_answer: any = {
    placeholder: this.translate.instant('forms.opinion.optional_message')
  }

  public model: any = {
    op_message: "",
    op_message_after_answer: ""
  }

  @Input() public part: Part;
  @Input() public expired: boolean;
  public enableSaveButton: boolean = false;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private translate: TranslateService) {

  }

  ngOnInit() {
    this.model.op_message = this.part.settings.op_message;
    this.model.op_message_after_answer = this.part.settings.op_message_after_answer;
    if(this.expired){
      this.disableInputs = true;
    }
  }

  public informationChanged() {
    this.patchPart.emit(this.model);
  }

  public onSubmit(values: any): void{
  }

}
