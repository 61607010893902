import { Injectable } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GetUserService implements Resolve<any> {

  constructor(private aService: AuthService) {

  }

  resolve() {
    if(this.aService.isAuthenticated()) {
      return this.aService.getUser().then(result => {
        if(result['status'] == 'successful')
          return result;
        else
          return {};
      }).catch(error => {
        // do some logging
        console.log(error);
        return {};
      });
    } else {
      return {};
    }
  }
}
