import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'status-error',
  templateUrl: './status-error.dialog.html',
  styleUrls: ['./status-error.dialog.scss']
})
export class StatusErrorDialog implements OnInit {

  public title: string;
  public content: string;

  constructor(public dialogRef: MatDialogRef<StatusErrorDialog>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = this.data.title;
    this.content = this.data.content;
  }

  ngOnInit() {

  }

}
