import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public wrongCredentials: boolean = false;

  constructor(private formBuilder: FormBuilder, private aService: AuthService, private router: Router) {

  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });
  }

  public onSubmit(values): void {
    this.aService.authenticate(values).then(result => {
      if(result && result['error']) {
        this.wrongCredentials = true;
      } else {
        let token = result['token'];
        this.loginForm.reset();
        localStorage.setItem('access_token', token);
        this.router.navigate(['/account']);
      }
    });
  }
}
