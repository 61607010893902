import { Component, OnInit } from '@angular/core';
import { Part } from '../../../interfaces/part';
import { PartService } from '../../../service/part.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadPhoto } from '../../../dialogs/upload_photo/upload_photo.dialog';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'creator-parts-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class CreatorPartsView implements OnInit {

  public part: Part;
  public showEditPartName: boolean = false;
  public imageUploading: boolean = false;
  public tourID: string;
  public tourSettings: any;
  public tourName: string;
  public expired: boolean;
  public warning: boolean;
  public free: boolean;

  constructor(private route: ActivatedRoute, private pApi: PartService, private dialog: MatDialog, private translate: TranslateService, private router: Router) {

  }

  ngOnInit() {
    this.part = this.route.snapshot.data.part[0].part;
    this.expired = this.route.snapshot.data.part[0].expire_data.expired;
    this.warning = this.route.snapshot.data.part[0].expire_data.warning;
    this.free = this.route.snapshot.data.part[0].expire_data.free;
    this.tourID = this.route.snapshot.data.part[1].tourID;
    this.tourSettings = this.route.snapshot.data.part[1].settings;
    this.tourName = this.route.snapshot.data.part[1].tourname;
  }

  public tabChanged(): void {
    if(this.route.children.length > 0)
      this.router.navigate(['/creator/tours', this.tourID, 'parts', this.part.id]);
  }

  public patchPart(data: any) {
    let postData = {
      tourID: this.tourID,
      partID: this.part.id,
      values: data
    }

    // save to database
    this.pApi.patchPartSettings(postData).then(() => {
      // patch values so that all tabs have the updated object
      let valuesToPatch = Object.keys(postData.values);
      valuesToPatch.forEach(value => {
        this.part.settings[value] = data[value];
      });
    });
  }

  public saveNewPartName(): void {
    if(this.part.title && this.part.title !== "") {
      let data = {
        tourID: this.tourID,
        partID: this.part.id,
        title: this.part.title
      }
      this.pApi.editPartName(data);
    }
  }

  public previewImage(event): void {
    if(event.target && event.target.files && event.target.files.length > 0) {
      const dialogRef = this.dialog.open(UploadPhoto, {
        width: '600px',
        data: {
          title: this.translate.instant('creator.parts.dialog_change_image'),
          circle: false,
          maintain_aspect_ratio: false,
          aspect_ratio_1: 1,
          aspect_ratio_2: 1,
          event: event
        }
      });
      dialogRef.afterClosed().subscribe((data) => {
        if(data && data.img) {
          this.imageUploading = true;
          this.updatePartImage(data.img);
        }
      });
    }
  }

  private updatePartImage(image: string): void {
    this.pApi.updatePartImage({image: image, partID: this.part.id, tourID: this.tourID}).then(result => {
      this.imageUploading = false;
      this.part.image = result['url'];
    }).catch(error => {
      console.log(error);
      this.imageUploading = false;
      alert("Something went wrong");
    });
  }

  public deletePartImage(): void {
    this.imageUploading = true;
    this.pApi.deletePartImage(this.tourID, this.part.id).then(() => {
      this.imageUploading = false;
      this.part.image = null;
    }).catch(error => {
      console.log(error);
      this.imageUploading = false;
      alert("Something went wrong");
    });
  }

}
