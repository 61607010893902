import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import { Tour } from '../../../interfaces/tour';
import { UploadPhoto } from '../../../dialogs/upload_photo/upload_photo.dialog';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import * as CustomEditor from '../../../../assets/js/ckeditor.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'closedquestion-form',
  templateUrl: './closedquestion.component.html',
  styleUrls: ['./closedquestion.component.scss']
})
export class ClosedquestionComponent implements OnInit {
  public ckEditor: any = CustomEditor;
  public config_cq_question: any = {
    placeholder: this.translate.instant('forms.question.hint')
  }
  public disableInputs: boolean = false;
  public config_cq_correct_answer_text: any = {
    placeholder: this.translate.instant('forms.question.correct_answer_text_body')
  }
  public config_cq_wrong_answer_text: any = {
    placeholder: this.translate.instant('forms.question.incorrect_answer_text_body')
  }
  public config_oq_cq_slogan_right_answer: any = {
    placeholder: this.translate.instant('forms.question.slogan_correct_body')
  }
  public config_oq_cq_slogan_wrong_answer: any = {
    placeholder: this.translate.instant('forms.question.slogan_incorrect_body')
  }
  public model: any = {
    cq_question: "",
    cq_correct_answer_text: "",
    cq_wrong_answer_text: "",
    oq_cq_slogan_right_answer: "",
    oq_cq_slogan_wrong_answer: ""
  }

  public closedQuestionForm: FormGroup;
  @Input() public part: Part;
  @Input() public tour: Tour;
  @Input() public expired: boolean;

  public answers: any = [{
    "id": 1,
    "answer": "",
    "points": 0,
    "correct": true
  }];

  public enableSaveButton: boolean = false;
  public showSlogan: boolean = false;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, public route: ActivatedRoute, private translate: TranslateService) {

  }

  ngOnInit() {
    this.model.cq_question = this.part.settings.cq_question;
    this.model.cq_correct_answer_text = this.part.settings.cq_correct_answer_text;
    this.model.cq_wrong_answer_text = this.part.settings.cq_wrong_answer_text;
    this.model.oq_cq_slogan_right_answer = this.part.settings.oq_cq_slogan_right_answer;
    this.model.oq_cq_slogan_wrong_answer = this.part.settings.oq_cq_slogan_wrong_answer;
    this.buildForm();
    if(this.part.settings.answers && this.part.settings.answers.length > 0)
      this.answers = this.part.settings.answers;

    if(this.part.settings.oq_cq_has_slogan)
      this.sloganChanged(this.part.settings.oq_cq_has_slogan);

    if(this.expired){
      this.closedQuestionForm.disable();
      this.disableInputs = true;
    }
  }

  public informationChanged() {
    this.patchPart.emit(this.model);
  }

  private buildForm(): void {
    this.closedQuestionForm = this.formBuilder.group({
      //cq_question: [this.part.settings.cq_question, [Validators.required, Validators.maxLength(155)]],
      cq_attempts_possible: [this.part.settings.cq_attempts_possible, [Validators.required]],
      cq_attempts_limited: [this.part.settings.cq_attempts_limited, []],
      cq_amount_of_attempts: [this.part.settings.cq_amount_of_attempts, []],
      //cq_correct_answer_text: [this.part.settings.cq_correct_answer_text, [Validators.maxLength(155)]],
      //cq_wrong_answer_text: [this.part.settings.cq_wrong_answer_text, [Validators.maxLength(155)]],
      oq_cq_has_slogan: [this.part.settings.oq_cq_has_slogan, [Validators.required]],
      //oq_cq_slogan_right_answer: [this.part.settings.oq_cq_slogan_right_answer, [Validators.maxLength(155)]],
      //oq_cq_slogan_wrong_answer: [this.part.settings.oq_cq_slogan_wrong_answer, [Validators.maxLength(155)]],
      oq_cq_has_correct_answer_image: [this.part.settings.oq_cq_has_correct_answer_image, [Validators.required]],
      oq_cq_correct_answer_image_url: [this.part.settings.oq_cq_correct_answer_image_url, []],
      oq_cq_has_incorrect_answer_image: [this.part.settings.oq_cq_has_incorrect_answer_image, [Validators.required]],
      oq_cq_incorrect_answer_image_url: [this.part.settings.oq_cq_incorrect_answer_image_url, []],
      oq_cq_has_clickable_tiles: [this.part.settings.oq_cq_has_clickable_tiles, [Validators.required]],
      oq_cq_has_allowed_time: [this.part.settings.oq_cq_has_allowed_time, [Validators.required]],
      oq_cq_allowed_time: [this.part.settings.oq_cq_allowed_time, [Validators.min(0)]]
    });

    this.closedQuestionForm.get('oq_cq_has_slogan').valueChanges.subscribe(value => {
      this.sloganChanged(value);
    });

    this.closedQuestionForm.get('cq_attempts_possible').valueChanges.subscribe(value => {
      if(value == "attempts")
        this.closedQuestionForm.get('cq_attempts_limited').setValidators([Validators.required]);
      else
        this.closedQuestionForm.get('cq_attempts_limited').clearValidators();

      this.closedQuestionForm.get('cq_attempts_limited').updateValueAndValidity();
    });

    this.closedQuestionForm.get('cq_attempts_limited').valueChanges.subscribe(value => {
      if(value)
        this.closedQuestionForm.get('cq_amount_of_attempts').setValidators([Validators.required]);
      else
        this.closedQuestionForm.get('cq_amount_of_attempts').clearValidators();

      this.closedQuestionForm.get('cq_amount_of_attempts').updateValueAndValidity();
    });

    setTimeout(() => {
      this.closedQuestionForm.valueChanges.subscribe(() => {
        if(this.closedQuestionForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)
  }

  public previewImage(event, correct_answer): void {
    if(event.target && event.target.files && event.target.files.length > 0) {
      const dialogRef = this.dialog.open(UploadPhoto, {
        width: '600px',
        data: {
          title: this.translate.instant('change_image'),
          circle: false,
          maintain_aspect_ratio: false,
          aspect_ratio_1: 3,
          aspect_ratio_2: 4,
          event: event
        }
      });
      dialogRef.afterClosed().subscribe((data) => {
        if(data && data.img) {

          if(correct_answer) {
            this.closedQuestionForm.get('oq_cq_correct_answer_image_url').setValue(data.img);
            this.patchPartFromParent(false, true, false);
          } else {
            this.closedQuestionForm.get('oq_cq_incorrect_answer_image_url').setValue(data.img);
            this.patchPartFromParent(false, false, true);
          }
        }
      });
    }
  }

  private sloganChanged(value: boolean): void {
    if(value) {
      this.showSlogan = true;
    } else {
      this.showSlogan = false;
    }
  }

  public patchPartFromParent(includeAnswers: boolean, includeCorrectImage: boolean, includeIncorrectImage: boolean): void {
    let valuesToUpdate = Object.assign({}, this.closedQuestionForm.value);

    if(!includeAnswers && valuesToUpdate.cq_answers)
      delete valuesToUpdate.cq_answers;
    else if(includeAnswers && this.answers[0].answer !== "")
      valuesToUpdate.cq_answers = JSON.stringify(this.answers);

    if(!includeCorrectImage)
      delete valuesToUpdate.oq_cq_correct_answer_image_url;
    if(!includeIncorrectImage)
      delete valuesToUpdate.oq_cq_incorrect_answer_image_url;

    this.patchPart.emit(valuesToUpdate);
  }

  public getNumber(key: string): number {
    return parseInt(key) + 1;
  }

  public addAnswer(): void {
    this.answers.push({
      "answer": "",
      "points": 0,
      "correct": false
    });
  }

  public radioChanged(value: any, answer: any): void {
    answer.correct = value;
    if(answer.answer !== "")
      this.patchPartFromParent(true, false, false);
  }

  public deleteAnswer(key: any): void {
    this.answers.splice(key, 1);
    this.patchPartFromParent(true, false, false);
  }

}
