import { Injectable } from '@angular/core';
import { TourService } from '../service/tour.service';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GetSpecificResultService implements Resolve<any> {

  constructor(private tApi: TourService) {

  }

  resolve(route: ActivatedRouteSnapshot) {

    let tourID = route.paramMap.get('id');
    let resultID = route.paramMap.get('resultid');

    return this.tApi.getResult(tourID, resultID).then(result => {
      if(result['status'] == 'successful')
        return result;
      else
        return {};
    }).catch(error => {
      // do some logging
      console.log(error);
      return {};
    });
  }
}
