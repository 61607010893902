import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomDialog } from '../../../dialogs/custom/custom.dialog';
import { SubscriptionService } from '../../../service/subscription.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class AccountInvoiceComponent implements OnInit {

  public user: any;
  public firm: any;
  public invoices: any;

  public displayedColumns: string[] = ['invoice_id', 'price', 'state', 'pay', 'invoice'];
  public resultsLength: number = 0;
  public dataSource: MatTableDataSource<ClientData> = new MatTableDataSource();
  public tableReady: boolean = false;

  public firmDataForm: FormGroup;
  public enableSaveButton: boolean = false;

  private frontEndUrl: string = environment.frontEndUrl;

  @ViewChild(MatPaginator, { static: false }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) public sort: MatSort;

  constructor(private formBuilder: FormBuilder, private aService: AuthService, private router: Router, private sService: SubscriptionService,
              private route: ActivatedRoute, private dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {
    //this.user = this.route.snapshot.data.data.user;
    this.invoices = this.route.snapshot.data.invoices.invoices;
    this.resultsLength = this.invoices.length;
    this.dataSource.data = this.invoices;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.tableReady = true;
    // this.sort.sortChange.subscribe(() => {
    //   this.paginator.pageIndex = 0;
    // });

  }

  public payNow(invoiceData): void {
    const dialogRef = this.dialog.open(CustomDialog, {data: {
          title: this.translate.instant('account.invoice.pop-up.pay_title'),
          content: this.translate.instant('account.invoice.pop-up.pay_content'),
          confirmButton: this.translate.instant('account.invoice.pop-up.pay_confirm'),
          cancelButton: this.translate.instant('account.invoice.pop-up.pay_cancel'),
          showCancel: true
        },
        disableClose: true
      });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        invoiceData.reDirectUrl = this.frontEndUrl + this.router.url;
        this.sService.payInvoice(invoiceData).then(result => {
          window.location.href = result['url'];
        }).catch(error => {
          console.log(error);
        });
      }
    });
  }


  public reSendInvoice(invoiceData): void {
    const dialogRef = this.dialog.open(CustomDialog, {data: {
          title: this.translate.instant('account.invoice.pop-up.invoice_title'),
          content: this.translate.instant('account.invoice.pop-up.invoice_content'),
          confirmButton: this.translate.instant('account.invoice.pop-up.invoice_confirm'),
          cancelButton: this.translate.instant('account.invoice.pop-up.invoice_cancel'),
          showCancel: true
        },
        disableClose: true
      });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.sService.reSendInvoice(invoiceData).then(result => {
          if(result['status'] == 'successful'){
            //this.enableSaveButton = false;
            //alert("succesvol opgeslagen");
          }
        }).catch(error => {
          console.log(error);
        });
      }
    });


  }
}
