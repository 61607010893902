import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Part } from '../../../interfaces/part';
import * as CustomEditor from '../../../../assets/js/ckeditor.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'photomoment-form',
  templateUrl: './photomoment.component.html',
  styleUrls: ['./photomoment.component.scss']
})
export class PhotomomentComponent implements OnInit {
  public ckEditor: any = CustomEditor;
  public config_pm_instruction: any = {
    placeholder: this.translate.instant('forms.photomoment.instruction')
  }
  public disableInputs: boolean = false;
  public config_pm_text_after_photo: any = {
    placeholder: this.translate.instant('forms.photomoment.after_photo_body')
  }

  public model: any = {
    pm_instruction: "",
    pm_text_after_photo: ""
  }
  @Input() public part: Part;
  @Input() public expired: boolean;
  public enableSaveButton: boolean = false;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {
    this.model.pm_instruction = this.part.settings.pm_instruction;
    this.model.pm_text_after_photo = this.part.settings.pm_text_after_photo;
    if(this.expired){
      this.disableInputs = true;
    }
  }

  public informationChanged() {
    this.patchPart.emit(this.model);
  }

  public onSubmit(values: any): void{
  }

}
