import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import * as CustomEditor from '../../../../assets/js/ckeditor.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'guessword-form',
  templateUrl: './guessword.component.html',
  styleUrls: ['./guessword.component.scss']
})
export class GuessWordForm implements OnInit {
  public ckEditor: any = CustomEditor;
  public config_gw_explaination: any = {
    placeholder: this.translate.instant('forms.guessword.instruction_body')
  }
  public config_gw_message_after: any = {
    placeholder: this.translate.instant('forms.guessword.message_after_solving_body')
  }

  public model: any = {
    gw_explaination: "",
    gw_message_after: ""
  }
  public disableInputs: boolean = false;

  public guesswordForm: FormGroup;
  @Input() public part: Part;
  @Input() public expired: boolean;
  public enableSaveButton: boolean = false;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private translate: TranslateService) {

  }

  ngOnInit() {
    this.model.gw_explaination = this.part.settings.gw_explaination;
    this.model.gw_message_after = this.part.settings.gw_message_after;
    this.buildForm();

    if(this.expired) {
      this.guesswordForm.disable();
      this.disableInputs = true;
    }
  }

  private buildForm(): void {
    this.guesswordForm = this.formBuilder.group({
      gw_word: [this.part.settings.gw_word, [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.-]*$/), Validators.maxLength(48)]],
      //gw_explaination: [this.part.settings.gw_explaination, [Validators.maxLength(255)]],
      gw_word_display: [{value: this.part.settings.gw_word_display, disabled: true}, [Validators.required, Validators.maxLength(48)]],
      //gw_message_after: [this.part.settings.gw_message_after, [Validators.maxLength(155)]],
      gw_hints_enabled: [this.part.settings.gw_hints_enabled, []]
    });

    this.guesswordForm.get('gw_word').valueChanges.subscribe(value => {
      if(this.guesswordForm.get('gw_word').valid) {
        let shuffledArray = this.shuffleArray(value.split(''));
        this.guesswordForm.get('gw_word_display').setValue(shuffledArray.join(""));
      }
    });

    setTimeout(() => {
      this.guesswordForm.valueChanges.subscribe(() => {
        if(this.guesswordForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)
  }

  public informationChanged() {
    this.patchPart.emit(this.model);
  }

  public patchPartFromParent(): void {
    this.patchPart.emit(this.guesswordForm.getRawValue());
  }

  private shuffleArray(array: string[]): any {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  public getWordError() {
    return this.guesswordForm.get('gw_word').hasError('required') ? this.translate.instant('forms.guessword.mandetory') :
        this.guesswordForm.get('gw_word').hasError('pattern') ? this.translate.instant('forms.guessword.mandetory_2') :
            '';
  }

  public onSubmit(values: any): void{
  }

}
