import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiURL: string = environment.apiUrl;

  private dialogOpen: boolean = false;

  constructor(private http: HttpClient) {

  }

  public logError(data: any) {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "logError", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public postContact(data: any) {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "postContact", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public getDialogOpen(): boolean {
    return this.dialogOpen;
  }

  public setDialogOpen(value: boolean): void {
    this.dialogOpen = value;
  }

  public generateQRCode(data: any) {
    return new Promise(resolve => {
  		this.http.get("https://api.qrserver.com/v1/create-qr-code/?size= " + data.chs +  " &data=" + data.chl)
  		// this.http.get("https://chart.googleapis.com/chart?cht=" + data.cht + "&chs=" + data.chs + "&chl=" + data.chl, {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }
}
