import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'custom_dialog',
  templateUrl: './custom.dialog.html',
  styleUrls: ['./custom.dialog.scss']
})
export class CustomDialog implements OnInit {

  public title: string;
  public content: string;
  public confirmButton: string;
  public cancelButton: string;
  public showCancel: boolean;

  constructor(public dialogRef: MatDialogRef<CustomDialog>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = this.data.title;
    this.confirmButton = this.data.confirmButton;
    this.cancelButton = this.data.cancelButton;
    this.content = this.data.content ? this.data.content : undefined;
    this.showCancel = this.data.showCancel;
  }

  ngOnInit() {

  }

  public confirm() {
    this.dialogRef.close({confirm: 1});
  }

  public dismiss() {
    this.dialogRef.close({confirm: 0});
  }

}
