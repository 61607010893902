import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartService {

  private apiURL: string = environment.apiUrl;

  constructor(private http: HttpClient) {

  }

  public getPart(tourID: string, partID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getPart/" + tourID + "/" + partID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public createNewClickArea(tourID: string, partID: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "createNewClickArea/" + tourID + "/" + partID, data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public updateClickableArea(tourID: string, partID: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "updateClickableArea/" + tourID + "/" + partID, data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public updateClickableAreaNavigation(tourID: string, partID: string, clickAreaID: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "updateClickableAreaNavigation/" + tourID + "/" + partID + "/" + clickAreaID, data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public getClickAreas(tourID: string, partID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getClickAreas/" + tourID + "/" + partID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public deleteClickarea(tourID: string, partID: string, clickAreaID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.delete(this.apiURL + "deleteClickarea/" + tourID + "/" + partID + "/" + clickAreaID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public updatePartOrder(tourID: string, parts: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "updatePartOrder/" + tourID, parts)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public copyPart(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "copyPart", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public uploadMedia(data: any) {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "uploadMedia", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public deleteMedia(data: any) {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "deleteMedia", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public createPart(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "createPart", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public patchPartSettings(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.patch(this.apiURL + "patchPartSettings", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public updatePartImage(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "editPartImage", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public editPartName(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "editPartName", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public deletePartImage(tourID: string, partID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.delete(this.apiURL + "deletePartImage/" + tourID + "/" + partID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public deletePart(tourID: string, partID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.delete(this.apiURL + "deletePart/" + tourID + "/" + partID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }
}
