import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from './material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element';
import { ColorPickerModule } from 'ngx-color-picker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// creator - components
import { CreatorToursList } from '../creator/tours/list/list.component';
import { CreatorToursView } from '../creator/tours/view/view.component';
import { CreatorPartsView } from '../creator/parts/view/view.component';

// results - components
import { MainResults } from '../results/main/main.component';
import { ShowResults } from '../results/show/show.component';
import { DetailResults } from '../results/detail/detail.component';

// home - components
import { HomeTOURtoDO } from '../home/tourtodo/tourtodo.component';
import { LoginComponent } from '../home/login/login.component';
import { AccountComponent } from '../home/account/account.component';
import { MuseumComponent } from '../home/museum/museum.component';
import { TourismComponent } from '../home/tourism/tourism.component';
import { EducationComponent } from '../home/education/education.component';
import { EventsComponent } from '../home/events/events.component';
import { PersonalComponent } from '../home/personal/personal.component';
import { ChoosePlanComponent } from '../home/choose-plan/choose-plan.component';
import { ContactComponent } from '../home/contact/contact.component';
import { RegisterComponent } from '../home/register/register.component';
import { VerifyEmailComponent } from '../home/verify-email/verify-email.component';
import { ForgotPasswordComponent } from '../home/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../home/reset-password/reset-password.component';
import { BusinessAbonnementComponent } from '../home/subscription/business-abonnement/business-abonnement.component';
import { AvgComponent } from '../home/avg/avg.component';
import { PrivacyComponent } from '../home/privacy/privacy.component';
import { TermsConditionsComponent } from '../home/terms_conditions/terms_conditions.component';

// temp directives
import { GuessWordForm } from '../directives/forms/guessword/guessword.component';
import { MediaForm } from '../directives/forms/media/media.component';
import { LocationForm } from '../directives/forms/location/location.component';
import { BeaconForm } from '../directives/forms/beacon/beacon.component';
import { QrcodeComponent } from '../directives/forms/qrcode/qrcode.component';
import { PointsComponent } from '../directives/forms/points/points.component';
import { InformationmomentComponent } from '../directives/forms/informationmoment/informationmoment.component';
import { OpenquestionComponent } from '../directives/forms/openquestion/openquestion.component';
import { SlidingpuzzleComponent } from '../directives/forms/slidingpuzzle/slidingpuzzle.component';
import { CertificateComponent } from '../directives/forms/certificate/certificate.component';
import { ClosedquestionComponent } from '../directives/forms/closedquestion/closedquestion.component';
import { OpinionComponent } from '../directives/forms/opinion/opinion.component';
import { PhotomomentComponent } from '../directives/forms/photomoment/photomoment.component';
import { ClicktilesComponent } from '../directives/forms/clicktiles/clicktiles.component';
import { ClickmapComponent } from '../directives/forms/clickmap/clickmap.component';
import { ClickmapComponentTour } from '../directives/tours/settings/clickmap/clickmap.component';

import { ExpireInfoComponent } from '../directives/expire-info/expire-info.component';

// Admin
import { AdminComponent } from '../admin/admin/admin.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AgmCoreModule,
    TranslateModule,
    DragDropModule,
    ResizableModule,
    ColorPickerModule,
    CKEditorModule
  ],
  declarations: [
    CreatorToursList,
    CreatorToursView,
    CreatorPartsView,

    MainResults,
    ShowResults,
    DetailResults,

    HomeTOURtoDO,
    MuseumComponent,
    LoginComponent,
    AccountComponent,
    TourismComponent,
    EducationComponent,
    EventsComponent,
    PersonalComponent,
    ChoosePlanComponent,
    ContactComponent,
    BusinessAbonnementComponent,
    AvgComponent,
    PrivacyComponent,
    TermsConditionsComponent,

    RegisterComponent,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,

    GuessWordForm,
    MediaForm,
    LocationForm,
    BeaconForm,
    QrcodeComponent,
    PointsComponent,
    InformationmomentComponent,
    OpenquestionComponent,
    SlidingpuzzleComponent,
    CertificateComponent,
    ClosedquestionComponent,
    OpinionComponent,
    PhotomomentComponent,
    ClicktilesComponent,
    ClickmapComponent,
    ExpireInfoComponent,
    ClickmapComponentTour,

    AdminComponent
  ],
  providers: [
    GoogleMapsAPIWrapper,
    DatePipe
  ]
})
export class ComponentsModule { }
