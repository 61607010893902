import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'TOURtoDO-tourism',
  templateUrl: './tourism.component.html',
  styleUrls: ['./tourism.component.scss']
})
export class TourismComponent implements OnInit {

  public showVideo: boolean = false;

  constructor(public aService: AuthService) {

  }

  ngOnInit() {

  }

  public playVid() {
    this.showVideo = true;
  }

}
