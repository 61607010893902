import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../../../service/subscription.service';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
import { CustomDialog } from '../../../dialogs/custom/custom.dialog';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class AccountChangePasswordComponent implements OnInit {

  public user: any;
  public firm: any;
  public firmDataForm: FormGroup;
  public enableSaveButton: boolean = false;

  constructor(private formBuilder: FormBuilder, private aService: AuthService, private router: Router, private sService: SubscriptionService,
              private route: ActivatedRoute, private dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {
    this.user = this.route.snapshot.data.data.user;
  }

  public changePassword(): void {
    this.aService.changePassword('resetME').then(result => {
      if(result && result['status'] && result['status'] == 'successful') {
        const dialogRef = this.dialog.open(CustomDialog, {data: {
              title: this.translate.instant('account.change-password.pop-up.pass_title'),
              content: this.translate.instant('account.change-password.pop-up.pass_content'),
              confirmButton: this.translate.instant('account.change-password.pop-up.pass_confirm'),
              showCancel: false
            },
            disableClose: true
          });
        dialogRef.afterClosed().subscribe((data) => {
            this.aService.logout().then(() => {
              localStorage.removeItem('access_token');
              this.router.navigate(['/login']);
            });
        });
      }
    }).catch(error => {
      console.log(error);
      alert("Something went wrong");
    });
  }
}
