import { NgModule } from '@angular/core';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatCardModule, MatMenuModule, MatTabsModule,
MatFormFieldModule, MatInputModule, MatSelectModule, MatSlideToggleModule, MatCheckboxModule, MatDialogModule, MatProgressBarModule,
MatProgressSpinnerModule, MatRadioModule, MatStepperModule, MatTooltipModule, MatPaginatorModule, MatSortModule, MatTableModule, MatExpansionModule } from '@angular/material';

@NgModule({
  imports: [
    MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatCardModule, MatMenuModule, MatTabsModule, MatFormFieldModule,
    MatInputModule, MatSelectModule, MatSlideToggleModule, MatCheckboxModule, MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
    MatStepperModule, MatTooltipModule, MatPaginatorModule, MatSortModule, MatTableModule, MatExpansionModule
  ],
  exports: [
    MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatCardModule, MatMenuModule, MatTabsModule, MatFormFieldModule,
    MatInputModule, MatSelectModule, MatSlideToggleModule, MatCheckboxModule, MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
    MatStepperModule, MatTooltipModule, MatPaginatorModule, MatSortModule, MatTableModule, MatExpansionModule
  ]
})
export class MaterialComponentsModule { }
