import { Injectable } from '@angular/core';
import { SubscriptionService } from '../service/subscription.service';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GetProductsService implements Resolve<any> {

  constructor(private sService: SubscriptionService) {

  }

  resolve() {
    return this.sService.getAllProducts().then(result => {
      if(result['status'] == 'successful')
        return result['products'];
      else
        return {};
    }).catch(error => {
      // do some logging
      console.log(error);
      return {};
    });
  }
}
