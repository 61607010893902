import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TourService } from '../../service/tour.service';

@Component({
  selector: 'copy-part',
  templateUrl: './copy-part.dialog.html',
  styleUrls: ['./copy-part.dialog.scss']
})
export class CopyPartDialog implements OnInit {

  public tourID: string;
  public copyTour: FormGroup;
  public part: any;
  
  public tours: any = [];

  constructor(
    public dialogRef: MatDialogRef<CopyPartDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private tourAPI: TourService
  ) {
    this.tourID = this.data.tourID;
    this.part = this.data.part;

    this.tourAPI.getTours().then(result => {
      this.tours = result.tours;
    });
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.copyTour = this.formBuilder.group({
      tour: ["", [Validators.required]]
    });
  }

  public submit() {
    let data = this.copyTour.value;
    this.dialogRef.close(data);
  }

  public dismiss() {
    this.dialogRef.close();
  }
}
