import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Router } from '@angular/router';
import { ConfirmDialog } from '../../dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;
  public error: any;

  constructor(private formBuilder: FormBuilder, private aService: AuthService, private router: Router, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.registerForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      password_repeat: ["", [Validators.required, Validators.minLength(6)]]
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  public onSubmit(values): void {
    this.aService.register(values).then(result => {
      if(result['status'] == 422)
        this.error = result['error'].errors;

      if(result['status'] == 'successful') {
        this.registerForm.reset();
        const dialogRef = this.dialog.open(ConfirmDialog, {
          data: {
            title: "Uw account is aangemaakt",
            content: "Er is een mail verstuurd met een link om uw account te activeren.",
            showCancel: false
          },
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigate(['/login']);
        });
      }

    }).catch(error => {
      console.log(error);
      alert("Something went wrong");
    });
  }
}

export class PasswordValidation {

  static MatchPassword(AC: AbstractControl) {
    let password = AC.get('password').value; // to get value in input tag
    let confirmPassword = AC.get('password_repeat').value; // to get value in input tag
    if(password != confirmPassword)
      AC.get('password_repeat').setErrors( { MatchPassword: true } );
    else
      return null;
  }
}
