import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../../../service/subscription.service';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class AccountSubscriptionComponent implements OnInit {

  public user: any;
  public products: any;
  public subscription: any;
  public expired: boolean;
  public warning: boolean;

  constructor(private aService: AuthService, private router: Router, private sService: SubscriptionService,
              private route: ActivatedRoute, private dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {
    this.sService.getPackages().then(result => {
      this.products = result;
    });

    this.user = this.route.snapshot.data.data.user;
    this.expired = this.route.snapshot.data.data.subscription.expired;
    this.warning = this.route.snapshot.data.data.subscription.warning;
    this.subscription = this.route.snapshot.data.data.subscription;
    this.subscription.start_date ? this.subscription.start_date = new Date(this.subscription.start_date) : undefined;
    this.subscription.end_date ? this.subscription.end_date = new Date(this.subscription.end_date) : undefined;
  }

  public stopAutoRenew(): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('account.subscription.pop_up_disable_title'),
        content: this.translate.instant('account.subscription.pop_up_disable_content'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.sService.stopAutoRenew().then(() => {
          this.showSucceedAlert(true);
          this.subscription.auto_renew = 0;
        });
      }
    });
  }

  public startAutoRenew(): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('account.subscription.pop_up_reenable_title'),
        content: this.translate.instant('account.subscription.pop_up_reenable_content'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.sService.startAutoRenew().then(() => {
          this.showSucceedAlert(false);
          this.subscription.auto_renew = 1;
        });
      }
    });
  }

  public switchToFree(): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('account.subscription.pop_up_reenable_title'),
        content: this.translate.instant('account.subscription.pop_up_reenable_content'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.sService.switchToFree().then(subscription => {
          this.showSucceedAlert(false);
          this.subscription = subscription;
          this.subscription.start_date ? this.subscription.start_date = new Date(this.subscription.start_date) : undefined;
          this.subscription.end_date ? this.subscription.end_date = new Date(this.subscription.end_date) : undefined;
        });
      }
    });
  }



  public createNewBusinessSubscription(): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('account.subscription.pop_up_create_title'),
        content: this.translate.instant('account.subscription.pop_up_create_content'),
        showCancel: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.confirm && data.confirm == 1) {
        this.router.navigate(["/chooseplan/business"]);
      }
    });
  }

  private showSucceedAlert(disabled: boolean): void {
    this.dialog.open(ConfirmDialog, {
      data: {
        title: disabled ? this.translate.instant('account.subscription.pop_up_succes_title_1') : this.translate.instant('account.subscription.pop_up_succes_title_2'),
        content: disabled ? this.translate.instant('account.subscription.pop_up_succes_content_1') : this.translate.instant('account.subscription.pop_up_succes_content_2'),
        showCancel: false
      },
      disableClose: true
    });
  }

  public logout(): void {
    this.aService.logout().then(() => {
      localStorage.removeItem('access_token');
      this.router.navigate(['/login']);
    });
  }

}
