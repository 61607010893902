import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../../service/subscription.service';
import { WorkshopDialog } from '../../dialogs/workshop/workshop.dialog';
import { ConfirmDialog } from '../../dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss']
})
export class ChoosePlanComponent implements OnInit {

  public user: any;
  public products: any;
  public subscription: any;
  public expired: boolean;
  public warning: boolean;
  private packageMapper: any;

  public authenticated: boolean = false;

  constructor(private route: ActivatedRoute, private sService: SubscriptionService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.sService.getPackages().then(result => {
      this.packageMapper = result;
    });

    if(this.route.snapshot.data.data && this.route.snapshot.data.data.user) {
      this.authenticated = true;

      this.user = this.route.snapshot.data.data.user;
      this.expired = this.route.snapshot.data.data.expire_data.expired;
      this.warning = this.route.snapshot.data.data.expire_data.warning;
      this.subscription = this.route.snapshot.data.data.subscription;
      this.subscription.start_date ? this.subscription.start_date = new Date(this.subscription.start_date) : undefined;
      this.subscription.end_date ? this.subscription.end_date = new Date(this.subscription.end_date) : undefined;
    }

    this.products = this.route.snapshot.data.products;
  }

  public preparePayment(typeToCheck: string): void {
    this.sService.preparePayment({
      type: 'premium',
      title: "Pakket " + this.packageMapper[typeToCheck].title
    }).then(result => {
      if(result['status'] == 'successful')
        window.location.href = result['url'];
    });
  }

  public contactForWorkshop(): void {
    const dialogRef = this.dialog.open(WorkshopDialog, {
      disableClose: true,
      data: {
        products: this.products
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data && data.email) {
        this.sService.requestWorkshop(data).then((result) => {
          if(result && result['status'] == 'successful')
            this.showSucceedAlert();
        });
      }
    });
  }

  private showSucceedAlert(): void {
    this.dialog.open(ConfirmDialog, {
      data: {
        title: "Uw aanvraag is verstuurd.",
        content: "Een kopie van de aanvraag is verstuurd naar uw opgegeven e-mail adres. Er wordt zo spoedig mogelijk contact met u opgenomen.",
        showCancel: false
      },
      disableClose: true
    });
  }
}
