import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'TOURtoDO-museum',
  templateUrl: './museum.component.html',
  styleUrls: ['./museum.component.scss']
})
export class MuseumComponent implements OnInit {

  public showVideo: boolean = false;

  constructor(public aService: AuthService) {

  }

  ngOnInit() {

  }

  public playVid() {
    this.showVideo = true;
  }

}
