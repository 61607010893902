import { Component, OnInit, Input, ViewChild, NgZone, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import { Tour } from '../../../interfaces/tour';
import { UploadPhoto } from '../../../dialogs/upload_photo/upload_photo.dialog';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare var google: any;

import { MapsAPILoader, AgmMap } from '@agm/core';
// import { GoogleMapsAPIWrapper } from '@agm/core/services';

@Component({
  selector: 'location-form',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationForm implements OnInit {

  public disableInputs: boolean = false;

  public locationForm: FormGroup;
  @Input() public part: Part;
  @Input() public expired: boolean;
  @Input() public tour: Tour;
  public enableSaveButton: boolean = false;
  private autocompleteConfigured: boolean = false;

  // map vars
  public geocoder: any;
  public zoom: number = 12;
  public searchControl: FormControl;

  @ViewChild("search", { static: false }) public searchElementRef: ElementRef;
  @ViewChild(AgmMap, { static: false }) public map: AgmMap;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, public mapsAPILoader: MapsAPILoader, private zone: NgZone, public route: ActivatedRoute, private translate: TranslateService
) {
    this.mapsAPILoader = mapsAPILoader;
    this.zone = zone;
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  ngOnInit() {
    this.buildForm();
    this.searchControl = new FormControl();
    if(!this.expired) {
      this.initializeMap();
    } else {
      this.locationForm.disable();
      this.searchControl.disable();
      this.disableInputs = true;
    }
  }

  private initializeMap(): void {

    if(!this.part.settings.ls_location_lat || !this.part.settings.ls_location_lng) //set current position
      this.setCurrentPosition();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      if(this.locationForm.get('ls_has_location').value == 1)
        setTimeout(() => {this.configureAutoComplete();}, 500);
    });
  }

  public patchPartFromParent(includeImage: boolean): void {
    if(!this.autocompleteConfigured && this.locationForm.get('ls_has_location').value == 1)
      setTimeout(() => {this.configureAutoComplete();}, 500);

    let valuesToUpdate = Object.assign({}, this.locationForm.value);
    if(!includeImage)
      delete valuesToUpdate.ls_map_image;

    this.patchPart.emit(valuesToUpdate);
  }

  private configureAutoComplete() {
    this.autocompleteConfigured = true;
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ["address"]
    });
    autocomplete.addListener("place_changed", () => {
      this.zone.run(() => {
        //get the place result
        let place: any = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null)
          return;

        //set latitude, longitude and zoom
        this.locationForm.get('ls_location_lat').setValue(place.geometry.location.lat());
        this.locationForm.get('ls_location_lng').setValue(place.geometry.location.lng());
        this.patchPartFromParent(false);
        this.zoom = 12;
      });
    });
  }

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationForm.get('ls_location_lat').setValue(position.coords.latitude);
        this.locationForm.get('ls_location_lng').setValue(position.coords.longitude);
        this.patchPartFromParent(false);
        this.zoom = 12;
      }, () => {
        this.locationForm.get('ls_location_lat').setValue(51.8429473);
        this.locationForm.get('ls_location_lng').setValue(5.7631173);
        this.patchPartFromParent(false);
        this.zoom = 12;
      }, {
        timeout: 500
      });
    }
  }

  public placeMarker(event: any) {
    this.locationForm.get('ls_location_lat').setValue(event.coords.lat);
    this.locationForm.get('ls_location_lng').setValue(event.coords.lng);
    this.patchPartFromParent(false);
  }

  private buildForm(): void {
    this.locationForm = this.formBuilder.group({
      ls_has_location: [this.part.settings.ls_has_location, [Validators.required]],
      ls_map_type: [this.part.settings.ls_map_type, [Validators.required]],
      ls_map_image: [this.part.settings.ls_map_image, []],
      ls_location_mandatory: [this.part.settings.ls_location_mandatory, [Validators.required]],
      ls_has_location_release_time: [this.part.settings.ls_has_location_release_time, [Validators.required]],
      ls_location_release_time: [this.part.settings.ls_location_release_time, [Validators.required]],
      ls_location_release_penalty: [this.part.settings.ls_location_release_penalty, [Validators.required]],
      ls_location_lat: [parseFloat(this.part.settings.ls_location_lat), [Validators.required]],
      ls_location_lng: [parseFloat(this.part.settings.ls_location_lng), [Validators.required]],
      ls_route_description: [this.part.settings.ls_route_description, [Validators.maxLength(155)]],
      ls_show_target: [this.part.settings.ls_show_target, [Validators.required]],
      ls_location_distance: [this.part.settings.ls_location_distance, [Validators.required]],
      ls_jump_to_item: [this.part.settings.ls_jump_to_item, [Validators.required]],
      ls_has_clickable_map: [this.part.settings.ls_has_clickable_map, [Validators.required]],
      ls_show_popup: [this.part.settings.ls_show_popup, [Validators.required]]
    });

    setTimeout(() => {
      this.locationForm.valueChanges.subscribe(() => {
        if(this.locationForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)
  }

  public maptypeChanged(value: any): void {
    if(value == "other_map") {
      this.locationForm.get('ls_map_image').setValidators([Validators.required]);
      this.locationForm.get('ls_map_image').updateValueAndValidity();
    } else {
      this.locationForm.get('ls_map_image').clearValidators();
      this.locationForm.get('ls_map_image').updateValueAndValidity();
    }
  }

  public previewImage(event): void {
    if(event.target && event.target.files && event.target.files.length > 0) {
      const dialogRef = this.dialog.open(UploadPhoto, {
        width: '600px',
        data: {
          title: this.translate.instant('forms.location.change_image'),
          circle: false,
          maintain_aspect_ratio: false,
          aspect_ratio_1: 3,
          aspect_ratio_2: 4,
          event: event
        }
      });
      dialogRef.afterClosed().subscribe((data) => {
        if(data && data.img) {
          this.locationForm.get('ls_map_image').setValue(data.img);
          this.patchPartFromParent(true);
        }
      });
    }
  }

  public onSubmit(values: any): void {
  }

}
