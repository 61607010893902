import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'log-in',
  templateUrl: './log-in.dialog.html',
  styleUrls: ['./log-in.dialog.scss']
})
export class LogInDialog {

  public username: string;

  constructor(public dialogRef: MatDialogRef<LogInDialog>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.username = this.data.username;
  }

  public dismiss() {
    this.dialogRef.close({confirm: 0});
  }

  public proceed() {
    this.dialogRef.close({confirm: 1});
  }
}
