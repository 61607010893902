import { Injectable } from '@angular/core';
import { TourService } from '../service/tour.service';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GetTourSettingsClicktilesService implements Resolve<any> {

  constructor(private tApi: TourService) {

  }

  resolve(route: ActivatedRouteSnapshot) {

    let tourID = route.parent.parent.paramMap.get('id');

    return this.tApi.getTourSettings(tourID).then(result => {
      if(result['status'] == 'successful')
        return { settings: result['settings'], tourID: result['tourID'], expire_data: result['expire_data'], tourcode: result['tourcode'] };
      else
        return { settings: {}, tourID: tourID, expired: false, tourcode: null };
    }).catch(error => {
      // do some logging
      console.log(error);
      return { settings: {}, tourID: tourID};
    });
  }
}
