import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'upload_photo',
  templateUrl: './upload_photo.dialog.html',
  styleUrls: ['./upload_photo.dialog.scss']
})
export class UploadPhoto implements OnInit {

  public hasImage: boolean = false;

  public imageChangedEvent: any = '';
  public croppedImage: any = '';

  public loading: boolean = false;
  public title: string = '';
  public maintain_aspect_ratio: boolean = false;
  public is_circle: boolean = true;
  public aspect_ratio: number;

  constructor(public dialogRef: MatDialogRef<UploadPhoto>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = this.data.title;
    this.maintain_aspect_ratio = this.data.maintain_aspect_ratio;
    this.is_circle = this.data.circle;
    this.aspect_ratio = this.data.aspect_ratio_1 / this.data.aspect_ratio_2;

    if(this.data.event && this.data.event !== "") {
      this.hasImage = true;
      this.imageChangedEvent = this.data.event;
    }

  }

  ngOnInit() {

  }

  public submit() {
    this.dialogRef.close({img: this.croppedImage});
  }

  public dismiss() {
    this.dialogRef.close();
  }

  public fileChangeEvent(event: any): void {
    if(event.target && event.target.files && event.target.files.length > 0) { // handle cancel button
      this.loading = true;
      this.imageChangedEvent = event;
      this.hasImage = true;
    }
  }

  public imageCropped(image: string) {
    this.croppedImage = image;
  }

  public imageLoaded() {
    this.loading = false;
  }

  public loadImageFailed() {
    console.log("failed");
  }

}
