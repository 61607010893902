import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public forgotPasswordForm: FormGroup;
  public showMessageAfterSubmit: boolean = false;

  constructor(private formBuilder: FormBuilder, private aService: AuthService) {

  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }

  public onSubmit(values): void {
    this.aService.forgotPassword(values).then(result => {
      if(result && result['status'] && result['status'] == 'successful') {
        this.forgotPasswordForm.reset();
        this.showMessageAfterSubmit = true;
      }
    }).catch(error => {
      console.log(error);
      alert("Something went wrong");
    });
  }

}
