import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { AuthService } from '../../service/auth.service';
import { DatePipe } from '@angular/common';
import { LogInDialog } from '../../dialogs/log-in/log-in.dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-home',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public loader: boolean = true;
  private users: any[] = [];
  public filteredUsers: any[] = [];

  constructor(private admin: AdminService, private datePipe: DatePipe, private dialog: MatDialog, private router: Router) {

  }

  public async ngOnInit(): Promise<void> {
    const result: any = await this.admin.getAllUsers();
    if(result && result.status && result.status === "successful") {
      this.users = result.users;
      this.filteredUsers = this.users;
    }
    this.loader = false;
  }

  public getSubscriptionString(user: any): string {
    let subscriptionString: string = "";

    if(user && user.subscriptions && user.subscriptions[0]) {
      const subscription = user.subscriptions[0];
      subscriptionString = subscription.type + " - Start: " + (subscription.start_date ? this.datePipe.transform(subscription.start_date, 'yyyy-dd-MM') : "Not set") + " - End: " + (subscription.end_date ? this.datePipe.transform(subscription.end_date, 'yyyy-dd-MM') : "Not set");
    }

    return subscriptionString;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.filteredUsers = this.users.filter((user: any) => {
      return user.email.toLowerCase().includes(filterValue) || (user.subscriptions && user.subscriptions[0] && user.subscriptions[0].type.includes(filterValue));
    });
  }

  public logInAsUser(user: any): void {
    this.dialog.open(LogInDialog, {
      data: {
        username: user.email
      },
      disableClose: false
    }).afterClosed()
      .subscribe(async(data: any) => {
        if(data && data.confirm && data.confirm === 1) {
          try {
            const result = await this.admin.switchToUserAsAdmin({user_id: user.id});

            if(result && result.status && result.status === "successful") {
              alert("Login successful");
              localStorage.setItem('access_token', result.token);
              this.router.navigate(['/']);
            }
          } catch(error) {
            alert("Log in failed, please try again.");
          }
        }
      });
  }
}
