import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'results-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowResults implements OnInit {

  public results: any = [];
  public tour: any;
  public showInfo: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router, private translate: TranslateService) {
    this.router.events.subscribe(() => {
      this.getData();
    });
  }

  ngOnInit() {
    this.getData();
  }

  private getData(): void {
    if(this.route.snapshot.data.results) {
      this.tour = this.route.snapshot.data.results.results.tour;
      this.results = this.generateArray(this.route.snapshot.data.results.results.results);
      this.showInfo = false;
    }
  }

  private generateArray(obj: any): any {
    return Object.keys(obj).map((key)=>{ return {key:key, value:obj[key]}});
  }

  public getKeyString(date: string): string {
    const givenDate = new Date(date);
    givenDate.setHours(0);
    givenDate.setMinutes(0);
    givenDate.setSeconds(0, 0);

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0, 0);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0, 0);

    if (givenDate.getTime() == today.getTime())
      return this.translate.instant('today');
    else if (givenDate.getTime() == yesterday.getTime())
      return this.translate.instant('yesterday');
    else
      return formatDate(givenDate, 'longDate', 'nl');
  }
}
