import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomDialog } from '../../../dialogs/custom/custom.dialog';
import { SubscriptionService } from '../../../service/subscription.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class AccountDeviceComponent implements OnInit {

    public user: any;
    public qrURL: string;

    public displayedColumns: string[] = ['name', 'date', 'delete'];
    public resultsLength: number = 0;
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();
    public tableReady: boolean = false;

    @ViewChild(MatPaginator, { static: false }) public paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) public sort: MatSort;

    constructor(private sService: SubscriptionService, private route: ActivatedRoute, private dialog: MatDialog, private translate: TranslateService) {

    }

    ngOnInit() {
      this.user = this.route.snapshot.data.devices;
      this.qrURL = "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=connect=" + this.user.user.id;
      // this.qrURL = 'https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=connect=' + this.user.user.id;
      this.resultsLength = this.user.devices.length;
      this.dataSource.data = this.user.devices.slice(0, this.user.subscription.test_devices);;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.tableReady = true;
    }

    public getDisabled(date: any){
      const date1 = new Date(date);
      const date2 = new Date();
      return date1 < date2;
    }

    public disconnectSpecificDevice(data: any, i: any): void {
      const dialogRef = this.dialog.open(CustomDialog, {data: {
            title: this.translate.instant('account.device.pop-up.specific_disconnect_title'),
            content: this.translate.instant('account.device.pop-up.specific_disconnect_content'),
            confirmButton: this.translate.instant('account.device.pop-up.specific_disconnect_confirm'),
            cancelButton: this.translate.instant('account.device.pop-up.specific_disconnect_cancel'),
            showCancel: true
          },
          disableClose: true
        });
      dialogRef.afterClosed().subscribe((dataNew) => {
        if(dataNew && dataNew.confirm && dataNew.confirm == 1) {
          this.sService.deleteTestDevice({id: data['id']}).then(result => {
            this.user.devices.splice(i, 1);
            this.dataSource.data = this.user.devices;
          }).catch(error => {
            console.log(error);
          });
        }
      });
    }
  }
