import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from './material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxQRCodeModule } from 'ngx-qrcode2';

// Directives
import { HeaderComponent } from './../directives/header/header.component';

import { CreatorLayout } from './../directives/layout/creator/creator-layout.component';
import { HomeLayout } from './../directives/layout/home/home-layout.component';

import { ToursOverview } from './../directives/tours/overview/overview.component';
import { ToursSettings } from './../directives/tours/settings/settings.component';

//Account
import { AccountDataComponent } from './../directives/account/data/data.component';
import { AccountInvoiceComponent } from './../directives/account/invoice/invoice.component';
import { AccountInvoiceDataComponent } from './../directives/account/invoice-data/invoice-data.component';
import { AccountSubscriptionComponent } from './../directives/account/subscription/subscription.component';
import { AccountChangePasswordComponent } from './../directives/account/change-password/change-password.component';
import { AccountDeviceComponent } from './../directives/account/device/device.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    DragDropModule,
    NgxQRCodeModule
  ],
  declarations: [
    HeaderComponent,
    CreatorLayout,
    HomeLayout,
    ToursOverview,
    ToursSettings,
    AccountSubscriptionComponent,
    AccountDataComponent,
    AccountInvoiceComponent,
    AccountInvoiceDataComponent,
    AccountChangePasswordComponent,
    AccountDeviceComponent
  ]
})
export class DirectivesModule { }
