import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from './material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FlexLayoutModule } from '@angular/flex-layout';

// Dialogs
import { UploadPhoto } from '../dialogs/upload_photo/upload_photo.dialog';
import { ConfirmDialog } from '../dialogs/confirm/confirm.dialog';
import { CustomDialog } from '../dialogs/custom/custom.dialog';
import { DeleteAccountDialog } from '../dialogs/delete-account/delete-account.dialog';
import { PickPartTypeDialog } from '../dialogs/pick-part-type/pick-part-type.dialog';
import { StatusErrorDialog } from '../dialogs/status-error/status-error.dialog';
import { ShareTourDialog } from '../dialogs/share-tour/share-tour.dialog';
import { WorkshopDialog } from '../dialogs/workshop/workshop.dialog';
import { CopyTourDialog } from '../dialogs/copy-tour/copy-tour.dialog';
import { CopyPartDialog } from '../dialogs/copy-part/copy-part.dialog';
import { CreateTourDialog } from '../dialogs/create-tour/create-tour.dialog';
import { LogInDialog } from '../dialogs/log-in/log-in.dialog';


import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ImageCropperModule,
    RouterModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule
  ],
  declarations: [
    // Dialogs
    UploadPhoto,
    ConfirmDialog,
    CustomDialog,
    DeleteAccountDialog,
    PickPartTypeDialog,
    StatusErrorDialog,
    ShareTourDialog,
    WorkshopDialog,
    CopyTourDialog,
    CopyPartDialog,
    CreateTourDialog,
    LogInDialog
  ],
  entryComponents: [
    UploadPhoto,
    ConfirmDialog,
    CustomDialog,
    DeleteAccountDialog,
    PickPartTypeDialog,
    StatusErrorDialog,
    ShareTourDialog,
    WorkshopDialog,
    CopyTourDialog,
    CopyPartDialog,
    CreateTourDialog,
    LogInDialog
  ]
})
export class DialogsModule { }
