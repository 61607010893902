import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../../service/subscription.service';
import { ConfirmDialog } from '../../dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public user: any;
  public products: any;
  public subscription: any;
  public expired: boolean;
  public warning: boolean;
  public hasFirm: boolean = false;

  constructor(private aService: AuthService, private router: Router, private sService: SubscriptionService, private route: ActivatedRoute, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.sService.getPackages().then(result => {
      this.products = result;
    });

    this.user = this.route.snapshot.data.data.user;
    this.expired = this.route.snapshot.data.data.expire_data.expired;
    this.warning = this.route.snapshot.data.data.expire_data.warning;
    this.subscription = this.route.snapshot.data.data.subscription;
    this.subscription.start_date ? this.subscription.start_date = new Date(this.subscription.start_date) : undefined;
    this.subscription.end_date ? this.subscription.end_date = new Date(this.subscription.end_date) : undefined;
    this.user.firm ? this.hasFirm = true : this.hasFirm = false;
  }

  public logout(): void {
    this.aService.logout().then(() => {
      localStorage.removeItem('access_token');
      this.router.navigate(['/login']);
    });
  }

  // public disableBusinessSubscription(): void {
  //   const dialogRef = this.dialog.open(ConfirmDialog, {
  //     data: {
  //       title: "Weet u zeker dat u uw abonnement wilt opzeggen?",
  //       content: "Uw abonnement wordt automatisch stopgezet na de einddatum",
  //       showCancel: true
  //     },
  //     disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe((data) => {
  //     if(data && data.confirm && data.confirm == 1) {
  //       this.sService.disableBusinessSubscription().then(() => {
  //         this.showSucceedAlert(true);
  //         this.subscription.renew_automatically = 0;
  //       });
  //     }
  //   });
  // }

  // public reEnableBusinessSubscription(): void {
  //   const dialogRef = this.dialog.open(ConfirmDialog, {
  //     data: {
  //       title: "Weet u zeker dat u uw abonnement wilt heractiveren?",
  //       content: "",
  //       showCancel: true
  //     },
  //     disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe((data) => {
  //     if(data && data.confirm && data.confirm == 1) {
  //       this.sService.reEnableBusinessSubscription().then(() => {
  //         this.showSucceedAlert(false);
  //         this.subscription.renew_automatically = 1;
  //       });
  //     }
  //   });
  // }

  // public createNewBusinessSubscription(): void {
  //   const dialogRef = this.dialog.open(ConfirmDialog, {
  //     data: {
  //       title: "Weet u zeker dat u een nieuw abonnement wilt afsluiten?",
  //       content: "Uw huidig abonnement wordt daarbij afgesloten",
  //       showCancel: true
  //     },
  //     disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe((data) => {
  //     if(data && data.confirm && data.confirm == 1) {
  //       this.router.navigate(["/chooseplan/business"]);
  //     }
  //   });
  // }

  // private showSucceedAlert(disabled: boolean): void {
  //   this.dialog.open(ConfirmDialog, {
  //     data: {
  //       title: disabled ? "Uw abonnement is opgezegt" : "Uw account is geheractiveerd",
  //       content: disabled ? "Er worden geen facturen meer verstuurd en uw abonnement verloopt automatisch na einddatum van uw abonnement" : "",
  //       showCancel: false
  //     },
  //     disableClose: true
  //   });
  // }



}
