import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Part } from '../../../interfaces/part';
import { UploadPhoto } from '../../../dialogs/upload_photo/upload_photo.dialog';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import * as CustomEditor from '../../../../assets/js/ckeditor.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'openquestion-form',
  templateUrl: './openquestion.component.html',
  styleUrls: ['./openquestion.component.scss']
})
export class OpenquestionComponent implements OnInit {
  public ckEditor: any = CustomEditor;
  public disableInputs: boolean = false;
  public config_oq_question: any = {
    placeholder: this.translate.instant('forms.question.hint')
  }
  public config_oq_correct_answer_text: any = {
    placeholder: this.translate.instant('forms.question.correct_answer_text_body')
  }
  public config_oq_wrong_answer_text: any = {
    placeholder: this.translate.instant('forms.question.incorrect_answer_text_body')
  }
  public config_oq_cq_slogan_right_answer: any = {
    placeholder: this.translate.instant('forms.question.slogan_correct_body')
  }
  public config_oq_cq_slogan_wrong_answer: any = {
    placeholder: this.translate.instant('forms.question.slogan_incorrect_body')
  }
  public model: any = {
    oq_question: "",
    oq_correct_answer_text: "",
    oq_wrong_answer_text: "",
    oq_cq_slogan_right_answer: "",
    oq_cq_slogan_wrong_answer: ""
  }

  public openquestionForm: FormGroup;
  @Input() public part: Part;
  @Input() public expired: boolean;

  public showLimitedAttemptsField: boolean = false;
  public showAmountOfAttemptsField: boolean = false;
  public enableSaveButton: boolean = false;

  @Output() public patchPart: EventEmitter<any> = new EventEmitter<any>();

  public showSlogan: boolean = false;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, public route: ActivatedRoute, private translate: TranslateService) {

  }

  ngOnInit() {
    this.model.oq_question = this.part.settings.oq_question;
    this.model.oq_correct_answer_text = this.part.settings.oq_correct_answer_text;
    this.model.oq_wrong_answer_text = this.part.settings.oq_wrong_answer_text;
    this.model.oq_cq_slogan_right_answer = this.part.settings.oq_cq_slogan_right_answer;
    this.model.oq_cq_slogan_wrong_answer = this.part.settings.oq_cq_slogan_wrong_answer;
    this.buildForm();
    this.configureShowAttemptsField(this.part.settings.oq_attempts_possible);
    this.configureShowAmountField(this.part.settings.oq_attempts_limited);

    if(this.part.settings.oq_cq_has_slogan)
      this.sloganChanged(this.part.settings.oq_cq_has_slogan);

    if(this.expired){
      this.openquestionForm.disable();
      this.disableInputs = true;
    }
  }

  public patchPartFromParent(includeCorrectImage: boolean, includeIncorrectImage: boolean): void {
    let valuesToUpdate = Object.assign({}, this.openquestionForm.value);
    if(!includeCorrectImage)
      delete valuesToUpdate.oq_cq_correct_answer_image_url;
    if(!includeIncorrectImage)
      delete valuesToUpdate.oq_cq_incorrect_answer_image_url;

    this.patchPart.emit(valuesToUpdate);
  }

  public informationChanged() {
    this.patchPart.emit(this.model);
  }

  private buildForm(): void {
    this.openquestionForm = this.formBuilder.group({
      //oq_question: [this.part.settings.oq_question, [Validators.required, Validators.maxLength(155)]],
      oq_answer: [this.part.settings.oq_answer, [Validators.required, Validators.maxLength(155)]],
      oq_case_sensitive: [this.part.settings.oq_case_sensitive, [Validators.required]],
      oq_attempts_possible: [this.part.settings.oq_attempts_possible, [Validators.required]],
      oq_attempts_limited: [this.part.settings.oq_attempts_limited, []],
      oq_amount_of_attempts: [this.part.settings.oq_amount_of_attempts, []],
      //oq_correct_answer_text: [this.part.settings.oq_correct_answer_text, [Validators.maxLength(155)]],
      //oq_wrong_answer_text: [this.part.settings.oq_wrong_answer_text, [Validators.maxLength(155)]],
      oq_cq_has_slogan: [this.part.settings.oq_cq_has_slogan, [Validators.required]],
      oq_cq_slogan_right_answer: [this.part.settings.oq_cq_slogan_right_answer, [Validators.maxLength(155)]],
      oq_cq_slogan_wrong_answer: [this.part.settings.oq_cq_slogan_wrong_answer, [Validators.maxLength(155)]],
      oq_cq_has_correct_answer_image: [this.part.settings.oq_cq_has_correct_answer_image, [Validators.required]],
      oq_cq_correct_answer_image_url: [this.part.settings.oq_cq_correct_answer_image_url, []],
      oq_cq_has_incorrect_answer_image: [this.part.settings.oq_cq_has_incorrect_answer_image, [Validators.required]],
      oq_cq_incorrect_answer_image_url: [this.part.settings.oq_cq_incorrect_answer_image_url, []],
      oq_cq_has_clickable_tiles: [this.part.settings.oq_cq_has_clickable_tiles, [Validators.required]],
      oq_cq_has_allowed_time: [this.part.settings.oq_cq_has_allowed_time, [Validators.required]],
      oq_cq_allowed_time: [this.part.settings.oq_cq_allowed_time, [Validators.min(0)]]
    });

    this.openquestionForm.get('oq_cq_has_slogan').valueChanges.subscribe(value => {
      this.sloganChanged(value);
    });


    this.openquestionForm.get('oq_attempts_possible').valueChanges.subscribe(value => {
      this.configureShowAttemptsField(value)
    });

    this.openquestionForm.get('oq_attempts_limited').valueChanges.subscribe(value => {
      this.configureShowAmountField(value);
    });

    setTimeout(() => {
      this.openquestionForm.valueChanges.subscribe(() => {
        if(this.openquestionForm.valid)
          this.enableSaveButton = true;
        else
          this.enableSaveButton = false;
      });
    }, 500)


  }

  private sloganChanged(value: boolean): void {
    if(value) {
      this.showSlogan = true;
    } else {
      this.showSlogan = false;
    }
  }

  private configureShowAmountField(value: any): void {
    if(value) {
      this.showAmountOfAttemptsField = true;
      this.openquestionForm.get('oq_amount_of_attempts').setValidators([Validators.required]);
    } else {
      this.showAmountOfAttemptsField = false;
      this.openquestionForm.get('oq_amount_of_attempts').clearValidators();
    }
    this.openquestionForm.get('oq_amount_of_attempts').updateValueAndValidity();
  }

  private configureShowAttemptsField(value: any): void {
    if(value == "attempts") {
      this.showLimitedAttemptsField = true;
      this.openquestionForm.get('oq_attempts_limited').setValidators([Validators.required]);
    } else {
      this.showLimitedAttemptsField = false;
      this.openquestionForm.get('oq_attempts_limited').clearValidators();
    }
    this.openquestionForm.get('oq_attempts_limited').updateValueAndValidity();
  }

  public previewImage(event, correct_answer): void {
    if(event.target && event.target.files && event.target.files.length > 0) {
      const dialogRef = this.dialog.open(UploadPhoto, {
        width: '600px',
        data: {
          title: this.translate.instant('change_image'),
          circle: false,
          maintain_aspect_ratio: false,
          aspect_ratio_1: 3,
          aspect_ratio_2: 4,
          event: event
        }
      });
      dialogRef.afterClosed().subscribe((data) => {
        if(data && data.img) {

          if(correct_answer) {
            this.openquestionForm.get('oq_cq_correct_answer_image_url').setValue(data.img);
            this.patchPartFromParent(true, false);
          } else {
            this.openquestionForm.get('oq_cq_incorrect_answer_image_url').setValue(data.img);
            this.patchPartFromParent(false, true);
          }
        }
      });
    }
  }
}
