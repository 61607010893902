import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { StatusErrorDialog } from '../../../dialogs/status-error/status-error.dialog';
import { ApiService } from '../../../service/api.service';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'creator-layout',
  templateUrl: './creator-layout.component.html',
  styleUrls: ['./creator-layout.component.scss']
})
export class CreatorLayout implements OnInit {

  private expireWatcher: any;
  private expireInterval: any;

  constructor(private dialog: MatDialog, public aApi: AuthService, private api: ApiService, private router: Router, private translate: TranslateService) {

  }

  ngOnInit() {
    if(!this.expireWatcher) {
      this.expireWatcher = new Observable(observer => {
        this.expireInterval = setInterval(() => {
          observer.next(this.aApi.getToken() ? this.aApi.getExpireDate() : undefined);
        }, 1000);
      });
      this.expireWatcher.subscribe(val => {
        if(val && new Date() > val && !this.api.getDialogOpen()) {
          this.api.setDialogOpen(true);
          const dialogRef = this.dialog.open(StatusErrorDialog, {
            width: '350px',
            data: {
              title: this.translate.instant('error.401.token_expired.title'),
              content: this.translate.instant('error.401.token_expired.content')
            }
          });
          dialogRef.afterClosed().subscribe(() => {
            this.api.setDialogOpen(false);
            this.router.navigate(['/login']);
            localStorage.removeItem('access_token');
          });
        }
      });
    }
  }

  ngOnDestroy() {
    if(this.expireInterval)
      clearInterval(this.expireInterval);
  }

}
