import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { TourService } from '../../service/tour.service';

@Component({
  selector: 'share-tour',
  templateUrl: './share-tour.dialog.html',
  styleUrls: ['./share-tour.dialog.scss']
})
export class ShareTourDialog implements OnInit {

  public emailForm: FormGroup;
  public emails: FormArray;
  public users: any = [];
  public pendingUsers: any = [];
  private tourID: string;
  public showButtons: boolean = false;
  private usersToDelete: any = [];

  constructor(public dialogRef: MatDialogRef<ShareTourDialog>, @Inject(MAT_DIALOG_DATA) private data: any, private formBuilder: FormBuilder,
    private tApi: TourService) {
      this.tourID = this.data.tourID;
  }

  get formData() {
    return <FormArray> this.emailForm.get('emails');
  }

  ngOnInit() {

    this.tApi.getTourUsers(this.tourID).then(result => {
      this.users = result['users'];
      this.pendingUsers = result['invited'];
      this.showButtons = true;
    }).catch(error => {
      console.log(error);
      alert("Something went wrong");
    });

    this.emailForm = this.formBuilder.group({
      emails: this.formBuilder.array([])
    });

    this.addEmail();
  }

  public addEmail(): void {
    this.emails = this.emailForm.get('emails') as FormArray;
    this.emails.push(this.addEmailFormField());
  }

  private addEmailFormField(): FormGroup {
    return new FormGroup({
      email: new FormControl("", [Validators.email, Validators.required])
    });
  }

  public deleteUserFromTour(user: any) {
    user.delete = true;
    this.usersToDelete.push(user);
  }

  public undeleteUser(user: any) {
    this.usersToDelete.forEach((value, key) => {
      if(value.email == user.email) {
        user.delete = false;
        this.usersToDelete.splice(key, 1);
      }
    });
  }

  public deleteEmail(key: any): void {
    this.emailForm.get('emails')['controls'].splice(key, 1);
  }

  public submit(): void {
    let data = {
      newUsers: this.emailForm.get('emails').value,
      usersToRemove: this.usersToDelete
    };
    this.dialogRef.close(data);
  }

  public dismiss() {
    this.dialogRef.close();
  }
}
