import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  private apiURL: string = environment.apiUrl;

  constructor(private http: HttpClient) {

  }

  public createNewClickArea(tourID: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "createNewClickArea/" + tourID, data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public updateClickableArea(tourID: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "updateClickableArea/" + tourID, data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public updateClickableAreaNavigation(tourID: string, clickAreaID: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "updateClickableAreaNavigation/" + tourID + "/" + clickAreaID, data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public getClickAreas(tourID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getClickAreas/" + tourID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public deleteClickarea(tourID: string, clickAreaID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.delete(this.apiURL + "deleteClickarea/" + tourID + "/" + clickAreaID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public createTour(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "createTour", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public importTour(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "importTour", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getTourUsers(tourID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getTourUsers/" + tourID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public shareTour(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "shareTour", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public copyTour(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "copyTour", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public updateTourSettings(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.patch(this.apiURL + "updateTourSettings", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public updateTourImage(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "editTourImage", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public editTourName(data: any): Promise<any> {
    return new Promise(resolve => {
      this.http.post(this.apiURL + "editTourName", data)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public deleteTourImage(tourID: any): Promise<any> {
    return new Promise(resolve => {
      this.http.delete(this.apiURL + "deleteTourImage/" + tourID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public deleteTour(tourID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.delete(this.apiURL + "deleteTour/" + tourID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getTour(tourID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getTour/" + tourID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getTourParts(tourID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getTourParts/" + tourID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getTourSettings(tourID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getTourSettings/" + tourID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getTours(): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getAllTours")
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getResultsAllTours(): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getResultsAllTours")
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
    });
  }

  public getResultsPerTour(tourID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getResultsPerTour/" + tourID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }

  public getResult(tourID: string, resultID: string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(this.apiURL + "getResult/" + tourID + "/" + resultID)
        .subscribe(data => {
          resolve(data);
        }, error => {
          resolve(error);
        });
  	});
  }
}
