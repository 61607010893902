import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'expire-info',
  templateUrl: './expire-info.component.html',
  styleUrls: ['./expire-info.component.scss']
})
export class ExpireInfoComponent implements OnInit {

  @Input() public expired: boolean;
  @Input() public warning: boolean;
  @Input() public free: boolean;

  constructor() {

  }

  ngOnInit() {

  }
}
