import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatTabChangeEvent, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'create-tour',
  templateUrl: './create-tour.dialog.html',
  styleUrls: ['./create-tour.dialog.scss']
})
export class CreateTourDialog implements OnInit {

  public createCopyTourForm: FormGroup;
  public tabIndex: number;

  constructor(public dialogRef: MatDialogRef<CreateTourDialog>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.tabIndex = this.data.tabIndex;
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.createCopyTourForm = this.formBuilder.group({
      tab_index: [this.tabIndex, [Validators.required]],
      tour_name: ["", []],
      tour_code: ["", []]
    });

    setTimeout(() => {
      if(this.tabIndex == 0) {
        this.createCopyTourForm.get('tour_code').setValidators([]);
        this.createCopyTourForm.get('tour_name').setValidators([Validators.required]);
      } else {
        this.createCopyTourForm.get('tour_code').setValidators([Validators.required]);
        this.createCopyTourForm.get('tour_name').setValidators([]);
      }

      this.createCopyTourForm.get('tour_code').updateValueAndValidity();
      this.createCopyTourForm.get('tour_name').updateValueAndValidity();

    }, 100);
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.createCopyTourForm.get('tab_index').setValue(tabChangeEvent.index);

    if(tabChangeEvent.index) {
      this.createCopyTourForm.get('tour_code').setValidators([Validators.required]);
      this.createCopyTourForm.get('tour_name').setValidators([]);
    } else {
      this.createCopyTourForm.get('tour_code').setValidators([]);
      this.createCopyTourForm.get('tour_name').setValidators([Validators.required]);
    }

    this.createCopyTourForm.get('tour_code').updateValueAndValidity();
    this.createCopyTourForm.get('tour_name').updateValueAndValidity();
  }

  public submit() {
    let data = this.createCopyTourForm.value;
    this.dialogRef.close(data);
  }

  public dismiss() {
    this.dialogRef.close();
  }
}
