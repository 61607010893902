import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ttd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('nl');

    // detect browser language if none is available in localstorage
    // set language on dutch if language is detected and set on dutch, or when no language can be detected
    // set language on english if language is detected & not dutch
    if(localStorage.getItem('lang')) {
      this.translate.use(localStorage.getItem('lang'));
    } else {
      let browserlang: string;
      if(navigator && navigator.language && navigator.language !== "") {
        if(navigator.language == "nl-NL") {
          browserlang = "nl";
        } else {
          browserlang = "en";
        }
      } else {
        browserlang = "nl";
      }
      this.translate.use(browserlang);
      localStorage.setItem('lang', browserlang);
    }
  }
}
