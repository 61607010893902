import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface tours {
  id: string;
  image: string;
  description: string;
  amount_of_parts: number;
  tourcode: string;
}

@Component({
  selector: 'results-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainResults implements OnInit {

  public tours: tours[] = [];
  public loader: boolean = false;
  public expired: boolean;
  public warning: boolean;
  public free: boolean;

  public showDetailBreadcrumb: boolean = false;
  public tourDetail: any;
  private tourDetailID: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.events.subscribe(() => {
      if(this.route.firstChild && this.route.snapshot.firstChild.paramMap.get('id')) {
        this.tourDetailID = this.route.snapshot.firstChild.paramMap.get('id');
        if(this.tourDetailID) {
          this.tourDetail = this.tours.find(tour => tour.id == this.tourDetailID);
          this.showDetailBreadcrumb = true;
        }
      } else {
        this.showDetailBreadcrumb = false;
      }
    });
  }

  ngOnInit() {
    this.free = this.route.snapshot.data.tours.expire_data.free;
    this.expired = this.route.snapshot.data.tours.expire_data.expired;
    this.warning = this.route.snapshot.data.tours.expire_data.warning;
    this.tours = this.route.snapshot.data.tours.tours || [];

    if(this.route.firstChild && this.route.snapshot.firstChild.paramMap.get('id')) {
      this.tourDetailID = this.route.snapshot.firstChild.paramMap.get('id');
      if(this.tourDetailID) {
        this.tourDetail = this.tours.find(tour => tour.id == this.tourDetailID);
        this.showDetailBreadcrumb = true;
      }
    }
  }
}
