import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { StatusErrorDialog } from '../dialogs/status-error/status-error.dialog';
import { MatDialog } from '@angular/material';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private dialog: MatDialog, private api: ApiService, private translate: TranslateService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(map((res: HttpResponse<any>) => {

      // refresh token if exists
      if(res.headers && res.headers.get('authorization'))
        localStorage.setItem('access_token', res.headers.get('authorization').split(' ')[1]);

      return res;
    }),catchError((error: HttpErrorResponse) => {
      this.handleAuthError(error);
      return throwError(error);
    }) as any);
  }

  private handleAuthError(error: any): void {
    // get status
    let status = error.status;

    // create empty json object for setting title & content of dialogs
    let data = {
      title: "",
      content: ""
    }

    // check if there isn't already a dialog open (multiple calls possible in 1 screen)
    if(!this.api.getDialogOpen()) {
      // no dialog open yet, so claim position
      this.api.setDialogOpen(true);

      let subscriptionExpired: boolean = false;
      // show different kind of dialogs based on error status
      switch(status) {
        default: // default behavior: treat it as a 500 error, meaning do not remove local storage jwt key on default
        case 500: // internal error
          data.title = this.translate.instant('error.500.title');
          data.content = this.translate.instant('error.500.content');
          break;
        case 403: // not allowed
        case 401: // unauthorized
          if(error.error && error.error.error && error.error.error == "token_expired") {
            data.title =  this.translate.instant('error.401.token_expired.title');
            data.content = this.translate.instant('error.401.token_expired.content');
          } else if(error.error && error.error.message && error.error.message == "subscription_expired") {
            data.title = this.translate.instant('error.401.subscription_expired.title');
            data.content = this.translate.instant('error.401.subscription_expired.content');
            subscriptionExpired = true;
          } else {
            data.title = this.translate.instant('error.401.title');
            data.content = this.translate.instant('error.401.content');
          }
          break;
        case 404: // not found
          data.title = this.translate.instant('error.404.title');
          data.content = this.translate.instant('error.404.content');
          break;
        case 422: // validator fail laravel
          if(error.error && error.error.error && error.error.error == "email_not_verified") {
            data.title = this.translate.instant('error.422.email_not_verified.content');
            data.content = this.translate.instant('error.422.email_not_verified.content');
          } else if(error.error.errors.invoice_email){
            data.title = this.translate.instant('error.422.email_incorrect.title');
            data.content = this.translate.instant('error.422.email_incorrect.content');
          } else {
            data.title = this.translate.instant('error.422.title');
            data.content = this.translate.instant('error.422.content');
          }
          break;
      }

      // post the error to the back-end for investigation
      let postData = {
        status: status + " - " + error.statusText,
        log: JSON.stringify(error.error),
        call: error.message,
        url: error.url
      }
      this.api.logError(postData);

      if(subscriptionExpired) { // in case of subscription expired error, redirect them to chooseplan to upgrade their account
        this.router.navigate(['/chooseplan']);
      } else { // otherwise do the normal behavior of punishing cheaters by removing access token & navigation to root
        // if status is 422, it means that it is a validation fail. we don't want to navigate to home because of a validation failure
        if(status !== 422)
          this.router.navigate(['/']);

        // 401 and 403 are for people trying to cheat. remove their token and also navigate to home such that they have to re-authenticate again
        if(status == 401 || status == 403 || status == 404)
          localStorage.removeItem('access_token');
      }

      // open dialog with right data
      const dialogRef = this.dialog.open(StatusErrorDialog, {
        width: '350px',
        data: data
      });
      dialogRef.afterClosed().subscribe(() => {
        // release the turn so that new errors on other screens can be displayed when occuring
        this.api.setDialogOpen(false);
      });
    }
  }
}
