import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Dutch locale
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

// Root component
import { AppComponent } from './app.component';

// modules
import { TourtodoRoutingModule } from './modules/tourtodo-routing.module';
import { MaterialComponentsModule } from './modules/material-components.module';
import { DialogsModule } from './modules/dialogs.module';
import { DirectivesModule } from './modules/directives.module';
import { ComponentsModule } from './modules/components.module';

// interceptors
import { ErrorInterceptor } from './service/error-interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['api.tourtodo.app', 'localhost', 'devapi.tourtodo.app'],
        blacklistedRoutes: ['']
      }
    }),
    AgmCoreModule.forRoot({apiKey: 'AIzaSyC7ZDYmmW0RBe6TKUPy-Pm535htmHnxthI '}),
    TourtodoRoutingModule,
    MaterialComponentsModule,
    DialogsModule,
    ComponentsModule,
    DirectivesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
