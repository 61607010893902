import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../../../service/subscription.service';
import { ConfirmDialog } from '../../../dialogs/confirm/confirm.dialog';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-account-invoice-data',
  templateUrl: './invoice-data.component.html',
  styleUrls: ['./invoice-data.component.scss']
})
export class AccountInvoiceDataComponent implements OnInit {

  public user: any;
  public firm: any;
  public firmDataForm: FormGroup;
  public enableSaveButton: boolean = false;

  constructor(private formBuilder: FormBuilder, private aService: AuthService, private router: Router, private sService: SubscriptionService,
              private route: ActivatedRoute, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.user = this.route.snapshot.data.data.user;
    this.firm = this.route.snapshot.data.data.firm;

    this.firmDataForm = this.formBuilder.group({
      name: [this.firm ? this.firm.name : "", Validators.required],
      street: [this.firm ? this.firm.street : "", Validators.required], // kenmerk moneybird api?
      number: [this.firm ? this.firm.number : "", Validators.required],
      invoice_email: [this.firm ? this.firm.invoice_email : "", [Validators.required, Validators.email]],
      city: [this.firm ? this.firm.city : "", Validators.required],
      zipcode: [this.firm ? this.firm.zipcode : "", Validators.required],
      kenmerk: [this.firm && this.firm.kenmerk ? this.firm.kenmerk : "", []],
      country: [this.firm ? this.firm.country : "", Validators.required],
      contact_surname: [this.firm ? this.firm.contact_surname : "", Validators.required],
      contact_name: [this.firm ? this.firm.contact_name : "", Validators.required]
    });

    this.firmDataForm.valueChanges.subscribe(val => {
      if(this.firmDataForm.valid)
        this.enableSaveButton = true;
    });
  }

  public updateInvoiceData(): void {
    this.sService.updateInvoiceData(this.firmDataForm.value).then(result => {
      if(result['status'] == 'successful'){
        this.enableSaveButton = false;
        //alert("succesvol opgeslagen");
      }
    }).catch(error => {
      console.log(error);
    });
  }
}
