import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'TOURtoDO',
  templateUrl: './tourtodo.component.html',
  styleUrls: ['./tourtodo.component.scss']
})
export class HomeTOURtoDO implements OnInit {

  public showVideo: boolean = false;

  constructor() {

  }

  ngOnInit() {

  }

  public playVideo(): void {
    this.showVideo = true;
  }

}
