import { Injectable } from '@angular/core';
import { TourService } from '../service/tour.service';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GetTourService implements Resolve<any> {

  constructor(private tApi: TourService) {

  }

  resolve(route: ActivatedRouteSnapshot) {
    return this.tApi.getTour(route.paramMap.get('id')).then(result => {
      if(result['status'] == 'successful')
        return result;
      else
        return {};
    }).catch(error => {
      // do some logging
      console.log(error);
      return {};
    });
  }
}
