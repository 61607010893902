import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'workshop',
  templateUrl: './workshop.dialog.html',
  styleUrls: ['./workshop.dialog.scss']
})
export class WorkshopDialog implements OnInit {

  public product: any;
  public contactWorkshopForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<WorkshopDialog>, @Inject(MAT_DIALOG_DATA) private data: any, private formBuilder: FormBuilder) {
    this.product = this.data.products.zakelijk_workshop;
  }

  ngOnInit() {
    this.contactWorkshopForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(35)]],
      comments: ["", [Validators.minLength(2), Validators.maxLength(155)]]
    });
  }

  public submit(): void {
    this.dialogRef.close(this.contactWorkshopForm.value);
  }

  public dismiss(): void {
    this.dialogRef.close();
  }
}
